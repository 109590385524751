import { useEffect, useState } from "react";
import { Button, notification, Table, Tag, Typography } from "antd";
import Page from "../../../elements/Page";

import HeaderItem from "../../../elements/HeaderItem";
import TableHead from "./TableHead";
import REQUESTS from "../../../../server/requests";
import ICONS from "../../../../config/icons";
import TableButtons from "../../../elements/TableButtons";
import MusicDrawer from "./draawer/MusicDrawer";
import ImageComponent from "../../../elements/ImageComponent";
import confirm from "antd/lib/modal/confirm";
import EN from "../../../../config/en";
import { useSelector } from "react-redux";
import ViewSettingsDrawer from "../../organizations/categories/ViewSettingsDrawer";
import AddButtonComponent from "../../../elements/AddButtonComponent";

const MusicPage = () => {
  const translations = useSelector((state) => state.globalState.translate);

  let debounceTimeOut;

  const [dataSource, setDataSource] = useState([]);
  const [genresList, setGenresList] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [editable, setEditable] = useState(null);
  const [loading, setLoading] = useState(false);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 50,
      fixed: "left",
    },
    {
      title: translations["Name"] || EN["Name"],
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: translations["Icon"] || EN["Icon"],
      dataIndex: "icon",
      key: "icon",
      align: "center",

      render: (text, record) => {
        return <ImageComponent src={text} className="tableImage" />;
      },
    },
    {
      title: translations["Description"] || EN["Description"],
      dataIndex: "description",
      key: "description",
      align: "center",
    },
    {
      title: translations["Category"] || EN["Category"],
      dataIndex: "category_id",
      key: "category_id",
      align: "center",
      // ellipsis: true,
      render: (text, record) => {
        const newCategories = record.musics_categories?.map((item) => {
          return genresList.find((i) => i.value === item.category_id);
        });

        return (
          <div
            style={{
              display: "grid",
              // gridTemplateColumns: "repeat(auto-fill, minmax(60px, 1fr))",
              gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",

              // display: "flex",
              // flexWrap: "wrap",
              // flexDirection: "column",
              // gap: 15,
            }}
          >
            {newCategories.map((item) => {
              return (
                <Typography.Paragraph>
                  <Tag color="blue">{item?.label}</Tag>
                </Typography.Paragraph>
              );
            })}
          </div>
        );
      },
    },
    {
      title: translations["Url"] || EN["Url"],
      dataIndex: "url",
      key: "url",
      align: "center",

      ellipsis: true,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      align: "center",

      render: (text, record) => {
        return (
          <TableButtons
            handleMenuClick={(e) => handleMenuClick(e.key, record)}
            buttons={[
              { key: "edit", text: translations["Edit"] || EN["Edit"], icon: ICONS.EDIT },
              {
                key: "delete",
                text: translations["Delete"] || EN["Delete"],
                icon: ICONS.DELETE,
              },
            ]}
          />
        );
      },
    },
  ];

  const getDataSource = () => {
    setLoading(true);

    const query = {
      limit,
      page: currentPage,
      total,
    };

    function successCallBack(data) {
      setLoading(false);
      setDataSource(data);
    }

    function errorCallBack(err) {
      setLoading(false);
      console.log(err);
    }

    try {
      REQUESTS.MUSIC.GET(query, successCallBack, errorCallBack);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const handleMenuClick = (key, record) => {
    switch (key) {
      case "edit":
        setEditable(record);
        setShowDrawer(true);

        break;
      case "delete":
        confirm({
          title: translations["Do you want to delete"] || EN["Do you want to delete"],
          onOk() {
            handleDelete(record.id);
          },
        });
        break;
      default:
        break;
    }
  };

  const getMusicCategories = () => {
    try {
      REQUESTS.MUSIC_CATEGORY.GET(
        {},
        (data) => {
          const newGenresList = data.map((item) => {
            return { label: item.name, value: item.id };
          });
          setGenresList(newGenresList);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddMusic = (values) => {
    setLoading(true);
    clearTimeout(debounceTimeOut);

    debounceTimeOut = setTimeout(() => {
      if (editable) {
        try {
          REQUESTS.MUSIC.EDIT(
            { id: editable.id, ...values },
            (data) => {
              setShowDrawer(false);
              setEditable(null);
              getDataSource();
              setLoading(false);
              notification.success({
                message:
                  translations["Music updated successfully"] ||
                  EN["Music updated successfully"],
              });
            },
            (error) => {
              setLoading(false);
              notification.error({
                message: translations["An error occurred"] || EN["An error occurred"],
              });
              console.log(error);
            }
          );
        } catch (error) {
          console.log(error);
          setLoading(false);
          notification.error({
            message: translations["An error occurred"] || EN["An error occurred"],
          });
        }
      } else {
        try {
          REQUESTS.MUSIC.ADD(
            values,
            (data) => {
              setShowDrawer(false);
              getDataSource();
              setLoading(false);
              notification.success({
                message:
                  translations["Music added successfully"] ||
                  EN["Music added successfully"],
              });
            },
            (error) => {
              setLoading(false);
              notification.error({
                message: translations["An error occurred"] || EN["An error occurred"],
              });
              console.log(error);
            }
          );
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      }
    }, 1000);
  };

  const handleDelete = (id) => {
    setLoading(true);
    try {
      REQUESTS.MUSIC.DELETE(
        id,
        (data) => {
          setLoading(false);
          notification.success({
            message:
              translations["Music deleted successfully"] ||
              EN["Music deleted successfully"],
          });
          getDataSource();
        },
        (error) => {
          setLoading(false);
          notification.error({
            message: translations["An error occurred"] || EN["An error occurred"],
          });
          console.log(error);
        }
      );
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleTableChange = (data) => {
    console.log(data);
    const { current, pageSize, total } = data;

    setCurrentPage(current);
    setLimit(pageSize);
    setTotal(total);

    // setCurrentPage(pagination.current);
    // setLimit(pagination.pageSize);
  };

  useEffect(() => {
    getDataSource();
    getMusicCategories();
  }, [limit, currentPage, total]);

  return (
    <Page>
      <div className="page-body">
        <h1>{translations["Music page"] || EN["Music page"]}</h1>
        <HeaderItem
          title={translations["Music list"] || EN["Music list"]}
          total={dataSource?.length}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <TableHead>
            <div
              style={{
                display: "flex",
                justifyContent: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <AddButtonComponent
                text={translations["Add music"] || EN["Add music"]}
                onClick={() => {
                  setShowDrawer(true);
                  setEditable(null);
                }}
              />
              {/* <Button
                onClick={() => {
                  setShowDrawer(true);
                  setEditable(null);
                }}
              >
                {translations["Add music"] || EN["Add music"]}
              </Button> */}
            </div>
          </TableHead>
        </div>
        <Table
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          onChange={handleTableChange}
          // scroll={{ x: "max-content", y: null }}
          pagination={{
            position: ["bottomCenter"],
            current: currentPage,
            total: total,
            showSizeChanger: true,
          }}
          sticky={{
            offsetHeader: 60,
            offsetScroll: 0,
          }}
        />
      </div>
      <MusicDrawer
        visible={showDrawer}
        onClose={() => {
          setShowDrawer(false);
          setEditable(null);
        }}
        editable={editable}
        genres={genresList}
        addMusic={handleAddMusic}
        loading={loading}
      />
    </Page>
  );
};

export default MusicPage;
