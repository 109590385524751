import { useState, useEffect } from "react";
import { Button, Input, Table, Typography } from "antd";
import Page from "../../../../elements/Page";
import PageHeader from "../../../../elements/PageHeader";
import HeaderItem from "../../../../elements/HeaderItem";
import REQUESTS from "../../../../../server/requests";
import ICONS from "../../../../../config/icons";
import BookPageDrawer from "./drawer/BokkingDrawer";
import TableButtons from "../../../../elements/TableButtons";
import { useSelector } from "react-redux";
import EN from "../../../../../config/en";
import confirm from "antd/lib/modal/confirm";

const BookPage = () => {
  const translate = useSelector((state) => state.globalState.translate);

  const [dataSource, setDataSource] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [showDrawer, setShowDrawer] = useState(false);
  const [bookId, setBookId] = useState(null);

  const [tableSearch, setTableSearch] = useState({
    name: "",
    email: "",
  });

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      align: "center",
      width: 50,
    },
    {
      title: translate["Name"] || EN["Name"],
      dataIndex: "name",
      key: "name",
      align: "center",
      search: true,
      filterDropdown: ({ confirm }) => {
        return (
          <Input
            allowClear
            autoFocus
            placeholder="Type text here"
            value={tableSearch.name}
            onChange={(e) => {
              setTableSearch({
                name: e.target.value ? [e.target.value] : [],
              });
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: () => {
        return <>{ICONS.SEARCHOUTLINED}</>;
      },
      onFilter: (value, record) => {
        console.log(value, record);

        return record.name.toLowerCase().includes(value.toLowerCase());
      },
    },

    {
      title: translate["Application Form"] || EN["Application Form"],
      dataIndex: "application_form",
      key: "application_form",
      align: "center",
    },
    {
      title: translate["Country"] || EN["Country"],
      dataIndex: "country",
      key: "country",
      align: "center",
    },
    {
      title: translate["Email"] || EN["Email"],
      dataIndex: "email",
      key: "email",
      align: "center",
      search: true,
    },
    {
      title: translate["Full name"] || EN["Full name"],
      dataIndex: "full_name",
      key: "full_name",
      align: "center",
    },
    {
      title: translate["Phone"] || EN["Phone"],
      dataIndex: "phone",
      key: "phone",
      align: "center",
    },
    {
      title: translate["Passport Number"] || EN["Passport Number"],
      dataIndex: "passport_number",
      key: "passport_number",
      align: "center",
    },
    {
      title: translate["Language"] || EN["Language"],
      dataIndex: "language",
      key: "language",
      align: "center",
    },
    {
      title: translate["Price"] || EN["Price"],
      dataIndex: "price",
      key: "price",
      align: "center",
    },
    {
      title: translate["Size"] || EN["Size"],
      dataIndex: "size",
      key: "size",
      align: "center",
    },
    {
      title: translate["Sofa Bed"] || EN["Sofa Bed"],
      dataIndex: "sofa_bed",
      key: "sofa_bed",
      align: "center",
    },
    {
      title: translate["Twin Beds"] || EN["Twin Beds"],
      dataIndex: "twin_beds",
      key: "twin_beds",
      align: "center",
    },
    {
      title: translate["Description"] || EN["Description"],
      dataIndex: "description",
      key: "description",
      align: "center",
    },
    {
      title: translate["Action"] || EN["Action"],
      key: "action",
      fixed: "right",
      align: "center",
      render: (text, record) => (
        <>
          <TableButtons
            handleMenuClick={(e) => handleTableClick(e.key, record)}
            buttons={[
              {
                key: "accept",
                text: translate["Accept"] || EN["Accept"],
                icon: ICONS.ACCEPT,
              },
              {
                key: "reject",
                text: translate["Reject"] || EN["Reject"],
                icon: ICONS.REJECT,
              },
              {
                key: "cancel",
                text: translate["Cancel"] || EN["Cancel"],
                icon: ICONS.CANCEL,
              },
            ]}
          />
        </>
      ),
    },
  ];
  const handleTableClick = (key, record) => {
    if (!record?.id) return;

    switch (key) {
      case "accept":
        setBookId(record?.id);
        setShowDrawer(true);

        break;

      case "reject":
        confirm({
          title: "Do you want to reject this booking?",
          onOk() {
            handleAccept(record?.id, "rejected");
          },
        });
        break;

      case "cancel":
        confirm({
          title: "Do you want to cancel this booking?",
          onOk() {
            handleAccept(record?.id, "cancelled");
          },
        });
        break;

      default:
        break;
    }
  };

  const handleAccept = (roomId, actionStatus) => {
    setLoading(true);
    const reqBody = {
      room_id: roomId,
      status: actionStatus,
    };

    const successCallBack = (response) => {
      console.log("Success:", response);
      setLoading(false);
      getDataSource();
    };

    const errorCallBack = (error) => {
      console.error("Error:", error);
      setLoading(false);
    };

    REQUESTS.BOOKING.BOOK.ACCEPT(bookId, reqBody, successCallBack, errorCallBack);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const getDataSource = () => {
    try {
      const successCallBack = (response) => {
        const newDataSource = response?.rows?.map((item, index) => {
          return {
            id: item?.id,
            name: item?.room?.name,
            price: item?.room?.price,
            size: item?.room?.size,
            sofa_bed: item?.room?.sofa_bed,
            twin_beds: item?.room?.twin_beds,
            description: item?.room?.description,
            application_form: item?.customer_persona_datum?.application_form,
            country: item?.customer_persona_datum?.country,
            email: item?.customer_persona_datum?.email,
            full_name: item?.customer_persona_datum?.full_name,
            language: item?.customer_persona_datum?.language,
            phone: item?.customer_persona_datum?.phone,
            passport_number: item?.customer_persona_datum?.passport_number,
          };
        });
        setDataSource(newDataSource);
      };

      const errorCallBack = (error) => {
        console.log(error, "error");
      };

      const searchParams = {
        customer: search,
        limit: pageSize,
        page: currentPage,
      };

      if (tableSearch.name) {
        searchParams.room_name = tableSearch.name[0] || "";
      }

      REQUESTS.BOOKING.BOOK.GET(searchParams, successCallBack, errorCallBack);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaginationChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const getRooms = (success, failure) => {
    try {
      const successCallBack = (response) => {
        setLoading(false);
        success(response);
      };

      const errorCallBack = (error) => {
        setLoading(false);
        failure(error);
      };

      REQUESTS.ROOMS.GET({}, successCallBack, errorCallBack);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getDataSource();
  }, [search, tableSearch, currentPage, pageSize]);

  return (
    <Page>
      <PageHeader title={translate["Book page"] || EN["Book page"]}>
        <HeaderItem
          total={dataSource?.length}
          title={translate["Book page"] || EN["Book page"]}
        />
        <div>
          <Input
            placeholder={translate["Search"] || EN["Search"]}
            onChange={handleChange}
            prefix={ICONS.SEARCHOUTLINED}
            allowClear
          />
        </div>
      </PageHeader>
      <div className="page-body">
        <Table
          columns={columns}
          onChange={handlePaginationChange}
          dataSource={dataSource}
          scroll={{
            x: 2200,
          }}
          pagination={{
            showLessItems: true,
            showSizeChanger: true,
            showQuickJumper: true,
            position: ["bottomCenter"],
            current: currentPage,
            pageSize: pageSize,
            total,
          }}
        />
      </div>
      <BookPageDrawer
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        room={getRooms}
        bookingId={bookId}
        save={(value) => handleAccept(value, "approved")}
        loading={loading}
      />
    </Page>
  );
};

export default BookPage;
