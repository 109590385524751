import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Input, Button, notification, Spin } from "antd";
import Page from "../../../elements/Page";
import PageHeader from "../../../elements/PageHeader";
import EN from "../../../../config/en";
import REQUESTS from "../../../../server/requests";
import TextArea from "antd/lib/input/TextArea";
import Translation from "../../../elements/Translation";
import ICONS from "../../../../config/icons";

const AboutPage = () => {
  const translate = useSelector((state) => state.globalState.translate);

  const [descriptionTranslations, setDescriptionTranslations] = useState([]);
  const [nameTranslation, setNameTranslation] = useState([]);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onChangeNameTranslation = (value, id) => {
    const name = {
      text: value,
      iso_code: id,
    };

    const newTranslations = [...nameTranslation];
    const index = newTranslations.findIndex((i) => i.iso_code === id);

    if (index >= 0) {
      newTranslations[index] = name;
    } else {
      newTranslations.push(name);
    }

    setNameTranslation(newTranslations);
  };

  const onChangeTranslation = (value, iso_cod) => {
    const description = {
      text: value,
      iso_code: iso_cod,
    };

    const newTranslations = [...descriptionTranslations];

    const index = newTranslations.findIndex((i) => i.iso_code === iso_cod);

    if (index >= 0) {
      newTranslations[index] = description;
    } else {
      newTranslations.push(description);
    }

    setDescriptionTranslations(newTranslations);
  };

  const handleFinish = (values) => {
    setLoading(true);
    const body = {
      title: nameTranslation,
      text: descriptionTranslations,
    };

    try {
      REQUESTS.BOOKING.ABOUT.ADD(
        body,
        (data) => {
          setLoading(false);
          notification.success({
            message:
              translate["About added successfully"] || EN["About added successfully"],
          });
        },
        (error) => {
          setLoading(false);
          console.log(error);
        }
      );
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getSocialLinks = () => {
    setLoading(true);
    try {
      REQUESTS.BOOKING.ABOUT.GET(
        {},
        (res) => {
          setLoading(false);
          form.setFieldsValue({
            title: res?.title,
            text: res?.text,
          });
        },
        (err) => {
          setLoading(false);
          console.log(err);
        }
      );
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getSocialLinks();
  }, []);

  return (
    <Page>
      <PageHeader title={translate["About Page"] || EN["About Page"]} />
      <div
        style={{
          width: "50%",
          padding: "20px",
          borderRadius: "5px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          margin: " 20px ",
        }}
      >
        <h1>{translate["Add About info"] || EN["Add About info"]}</h1>
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Translation
            render={(item) => {
              const name = nameTranslation.find(
                (translation) => translation.iso_code === item.id
              )?.text;
              return (
                <Form.Item
                  label={translate["Title"] || EN["Title"]}
                  name="title"
                  tooltip={{ title: "Info Title", icon: ICONS.INFO }}
                >
                  <Input
                    value={name || ""}
                    onChange={(e) =>
                      onChangeNameTranslation(e.target.value, item.iso_code)
                    }
                  />
                </Form.Item>
              );
            }}
          />
          <Translation
            render={(item) => {
              const description = descriptionTranslations.find(
                (translation) => translation.iso_code === item.iso_code
              )?.text;
              return (
                <Form.Item
                  label={translate["Text"] || EN["Text"]}
                  name="text"
                  tooltip={{ title: "Info Title", icon: ICONS.INFO }}
                >
                  <TextArea
                    value={description || ""}
                    onChange={(e) => onChangeTranslation(e.target.value, item.iso_code)}
                  />
                </Form.Item>
              );
            }}
          />

          <Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <Button type="primary" htmlType="submit" loading={loading}>
                {translate["Save"] || EN["Save"]}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
            backgroundColor: "rgb(0 0 0 / 58%)",
          }}
        >
          <Spin spinning={loading} size="large" />
        </div>
      )}
    </Page>
  );
};

export default AboutPage;
