const EN = {
  // a
  Active: "Active",
  Airports: "Airports",
  Add: "Add",
  Adults: "Adults",
  "Add Language": "Add Language",
  "Add Room": "Add Room",
  "Add Guest": "Add Guest",
  "Add Channel": "Add Channel",
  "Add Category": "Add Category",
  "Add Product": "Add Product",
  "Add TV": "Add TV",
  "Add Group": "Add Group",
  "Add GUI": "Add GUI",
  "Add Package": "Add Package",
  "Add Movie": "Add Movie",
  "Add Movies": "Add Movies",
  "Add Media Library": "Add Media Library",
  "Add Epg Link": "Add Epg Link",
  "Add News": "Add News",
  "Add User": "Add User",
  "Add Service": "Add Service",
  "Add Season": "Add Season",
  "Add Episode": "Add Episode",
  "Add Staff Member": "Add Staff Member",
  "Add Feedback": "Add Feedback",
  "Add Organization Info": "Add Organization Info",
  "Add Info": "Add Info",
  "Add Info Center": "Add Info Center",
  "Add Local Info": "Add Local Info",
  "Add Guest Group": "Add Guest Group",
  "Add Location": "Add Location",
  "Add music": "Add music",

  "Add Orders": "Add Orders",
  "Add Useful Info": "Add Useful Info",
  "Add Quick Messages": "Add Quick Messages",
  "Add Country": "Add Country",

  "Add New GUI": "Add New GUI",
  "Add new Packages": "Add new Packages",
  "Add New Movies": "Add New Movies",
  "Add New TV": "Add New TV",
  "Add New Guest": "Add New Guest",
  "Add New Epg Link": "Add New Epg Link",
  "Add New Product": "Add New Product",
  "Add New Category": "Add New Category",
  "Add New User": "Add New User",
  "Add New Service": "Add New Service",
  "Add New Episode": "Add New Episode",
  "Add New Channel": "Add New Channel",
  "Add New Feedback": "Add New Feedback",
  "Add New Local Info": "Add New Local Info",
  "Add New Organization Info": "Add New Organization Info",
  "Add New Location": "Add New Location",
  "Add New Genre": "Add New Genre",
  "Add New Orders": "Add New Orders",
  "Add New Useful Info": "Add New Useful Info",
  "Add New Media Library": "Add New Media Library",
  "Add New Info": "Add New Info",
  "Add New Country": "Add New Country",

  "All products free": "All products free",
  "All live tv for free": "All live tv for free",
  "All services free": "All services free",
  "All movies for free": "All movies for free",
  "All rooms": "All rooms",
  "All notifications off": "All notifications off",

  "Access to all channels": "Access to all channels",
  "Access to all movies": "Access to all movies",
  "Access to all widgets": "Access to all widgets",
  "Access to all services and products": "Access to all services and products",
  "An error occurred": "An error occurred",
  "Add About info": "Add About info",
  "About Page": "About Page",
  "About added successfully": "About added successfully",
  "About info deleted successfully": "About info deleted successfully",

  Activate: "Activate",
  Activated: "Activated",

  "Accepted Date": "Accepted Date",
  "Address and Location": "Address and Location",
  "Arrival Date": "Arrival Date",

  "Answer message": "Answer message",
  "Airport settings": "Airport settings",

  "Attach file": "Attach file",
  "Attach Package": "Attach Package",
  "Attach Orders": "Attach Orders",
  "Attach Products": "Attach Products",
  "Attach Services": "Attach Services",
  "Attach Slider": "Attach Slider",
  Articles: "Articles",

  Access: "Access",
  Accept: "Accept",
  Accepted: "Accepted",
  About: "About",
  "About guest": "About guest",
  Admin: "Admin",
  Audio: "Audio",
  "Abount host": "Abount host",
  Application: "Application",
  "Add Application": "Add Application",
  "Add New Application": "Add New Application",
  "Application Id": "Application Id",
  "Add New Screen Mirroring": "Add New Screen Mirroring",
  "Add Screen": "Add Screen",
  "Add Company": "Add Company",
  "Add Genre": "Add Genre",
  "Add Radio": "Add Radio",
  "Add customer story": "Add customer story",
  "Article Page": "Article Page",
  "Add Article": "Add Article",
  "Edit Article": "Edit Article",
  "About info updated successfully": "About info updated successfully",
  "Article info updated successfully": "Article info updated successfully",
  "Article added successfully": "Article added successfully",
  "Article info deleted successfully": "Article info deleted successfully",
  "Add Blog": "Add Blog",
  "Auth configs": "Auth configs",
  "Add Slider": "Add Slider",
  Amount: "Amount",
  "Add Property": "Add Property",
  "Add Facility": "Add Facility",
  "Add Room": "Add Room",
  "Application Form": "Application Form",
  Action: "Action",

  // b
  "Back to login": "Back to login",
  Backdrop: "Backdrop",
  Background: "Background",
  Booking: "Booking",
  Book: "Book",
  "Booking Room": "Booking Room",
  Blog: "Blog",
  "Blog info updated successfully": "Blog info updated successfully",
  "Blog added successfully": "Blog added successfully",
  "Blog info deleted successfully": "Blog info deleted successfully",
  "Blog Page": "Blog Page",
  "Billing history": "Billing history",
  "Billing History Page": "Billing History Page",
  "Book page": "Book page",

  // c
  Currency: "Currency",
  Code: "Code",
  Create: "Create",
  Cancel: "Cancel",
  Category: "Category",

  Companies: "Companies",

  "Check your email": "Check your email",

  "Check In / Check out": "Check In / Check out",
  Channel: "Channel",
  "Channel number": "Channel number",
  Categories: "Categories",
  Country: "Country",
  Countries: "Countries",
  "Country Settings": "Country Settings",
  "Create rooms": "Create rooms",
  "Create device": "Create device",
  "Created Data": "Created Data",
  "Currency symbol": "Currency symbol",
  "Confirm password": "Confirm password",
  Comment: "Comment",
  "Contact persons name": "Contact persons name",
  "Contact phone": "Contact phone",
  City: "City",

  "Chat with": "Chat with",
  "Change password": "Change password",

  Completed: "Completed",
  "Change this order status": "Change this order status",
  "Change this service status": "Change this service status",
  "Curent guest orders": "Curent guest orders",
  Client: "Client",
  "°C": "°C",

  "Choose file": "Choose file",
  Continue: "Continue",
  Customers: "Customers",
  "Customer Story": "Customer Story",
  "Customer story added successfully": "Customer story added successfully",
  "Customer story updated successfully": "Customer story updated successfully",
  "Customer story deleted successfully": "Customer story deleted successfully",
  Customer: "Customer",
  Children: "Children",

  // d
  "Device Type": "Device Type",
  Due: "Due",
  Date: "Date",
  Deactivate: "Deactivate",
  "Date hour format": "Date hour format",
  "Date type": "Date type",
  "Date format": "Date format",
  Default: "Default",
  "Default GUI": "Default GUI",
  "Default temperature": "Default temperature",
  Delete: "Delete",
  Dashboard: "Dashboard",
  Description: "Description",
  Device: "Device",
  Devices: "Devices",
  "Devices Counter": "Devices Counter",
  "Discount for channels": "Discount for channels",
  "Discount for services": "Discount for services",
  "Discount for ovd": "Discount for ovd",
  "Discount for products": "Discount for products",
  "Drag and drop gallery or click here to upload it":
    "Drag and drop gallery or click here to upload it",
  "Device Id": "Device Id",
  "Date & time": "Date & time",
  "Do you want to logout": "Do you want to logout",
  "Device not found": "Device not found",
  Disable: "Disable",

  // e
  "Edit Company": "Edit Company",
  "E-Commerce": "E-Commerce",
  "E-commerce catagory": "E-commerce catagory",
  Edit: "Edit",
  "Epg id": "Epg id",
  "Edit User": "Edit User",
  "Edit Group": "Edit Group",
  "Edit Epg Link": "Edit Epg Link",
  "Edit News": "Edit News",
  "Edit Tv": "Edit Tv",
  "Edit Device": "Edit Device",
  "Edit Language": "Edit Language",
  "Edit Customer Story": "Edit Customer Story",

  Enable: "Enable",
  "Edit GUI": "Edit GUI",
  "Edit Country": "Edit Country",

  "Edit Product": "Edit Product",
  "Edit Packege": "Edit Packege",

  "Edit Account": "Edit Account",

  "Edit Category": "Edit Category",
  "Edit Channel": "Edit Channel",

  "Edit Service": "Edit Service",
  "Edit Season": "Edit Season",

  "Edit Logo": "Edit Logo",
  "Edit Background": "Edit Background",
  "Edit Blog": "Edit Blog",

  "Edit Feedback": "Edit Feedback",
  Earnings: "Earnings",
  Email: "Email",
  "Email address": "Email address",
  "Epg Links": "Epg Links",
  "Enter your email address and we will send you instructions to reset your account password":
    "Enter your email address and we will send you instructions to reset your account password",
  "Edit and manage basic information about organization":
    "Edit and manage basic information about organization",

  "Edit Organization Info": "Edit Organization Info",
  "Edit Location": "Edit Location",
  "Edit Local Info": "Edit Local Info",
  Episode: "Episode",
  Episodes: "Episodes",
  "Edit Episode": "Edit Episode",
  "Episode count": "Episode count",
  "Edit Genre": "Edit Genre",
  "Edit New Useful Info": "Edit New Useful Info",
  "Edit Quick Messages": "Edit Quick Messages",
  "Edit Media Library": "Edit Media Library",
  "Edit Info": "Edit Info",
  "Edit Application": "Edit Application",
  Enabled: "Enabled",
  "Edit Screen Mirroring": "Edit Screen Mirroring",
  "Enter Code": "Enter Code",
  "Edit Radio": "Edit Radio",
  "Edit Music": "Edit Music",
  "Edit About": "Edit About",
  "Edit Slider": "Edit Slider",
  "Edit Property": "Edit Property",
  "Edit Facility": "Edit Facility",
  "Edit Room": "Edit Room",

  // f
  "Full name": "Full name",
  "Full access": "Full access",

  Filter: "Filter",
  "Forgot password": "Forgot password",
  Feedback: "Feedback",
  "Feedback Settings": "Feedback Settings",
  "Feedback History": "Feedback History",
  "Feedback Questions": "Feedback Questions",
  "From TMDB": "From TMDB",
  "Font Size": "Font Size",
  File: "File",
  Facilities: "Facilities",

  "Facilities Page": "Facilities Page",
  // "Add Facility": "Add Facility",
  "Facilities updated successfully": "Facilities updated successfully",
  "Facilities added successfully": "Facilities added successfully",
  "Facilities deleted successfully": "Facilities deleted successfully",
  "Facilities info updated successfully": "Facilities info updated successfully",
  "Facilities info deleted successfully": "Facilities info deleted successfully",
  "Facilities information": "Facilities information",
  Facilities: "Facilities",
  "Field is required": "Field is required",

  // g
  Group: "Group",
  Groups: "Groups",
  "Group name": "Group name",
  "Guest groups": "Guest groups",

  Guest: "Guest",
  "Guest Information": "Guest Information",

  "GUEST MESSAGES": "GUEST MESSAGES",
  "GUEST MESSAGE SETTINGS": "GUEST MESSAGE SETTINGS",
  "Guest Name": "Guest Name",
  "Guest Country": "Guest Country",
  Generals: "Generals",
  Geners: "Geners",
  Gallery: "Gallery",
  Galleries: "Galleries",
  GUI: "GUI",
  "GUI Settings": "GUI Settings",
  Genres: "Genres",
  "Gallery format must be (png, jpg, jpeg, mp4, webm, ogg)":
    "Gallery format must be (png, jpg, jpeg, mp4, webm, ogg)",
  "Go Back": "Go Back",
  "General preferences": "General preferences",

  // h
  Host: "Host",
  "Host name": "Host name",
  "Host group": "Host group",
  "Host groups": "Host groups",
  "Hotel staff": "Hotel staff",
  "Hotel Info": "Hotel Info",
  "HTTP address": "HTTP address",
  "Host Information": "Host Information",

  // i
  IP: "IP",
  IT: "IT",
  INBOX: "INBOX",
  Image: "Image",
  "Import from CSV": "Import from CSV",
  "Iso code": "Iso code",
  "Instruction for password reset have been sent to following email address: max.rosari@ristv.com":
    "Instruction for password reset have been sent to following email address: max.rosari@ristv.com",
  "Import m3u file": "Import m3u file",
  "Info Center": "Info Center",
  "Info List": "Info List",
  "Inactivated Element": "Inactivated Element",
  "Integrate with other PMS systems": "Integrate with other PMS systems",
  "Info title": "Info title",
  Icon: "Icon",
  // j
  // k
  // l
  "Music updated successfully": "Music updated successfully",
  "Music added successfully": "Music added successfully",
  "Music page": "Music page",
  "Music list": "Music list",
  Location: "Location",
  "Location No": "Location No",
  Locations: "Locations",
  "Location name": "Location name",
  "Location number": "Location number",
  "Location Counter": "Location Counter",
  "Local Info": "Local Info",

  "Log in": "Log in",
  Language: "Language",
  Languages: "Languages",
  "Languages Settings": "Languages Settings",
  "Language, formats and units": "Language, formats and units",
  "List of rooms": "List of rooms",
  "List is empty": "List is empty",
  Logo: "Logo",
  "Live TV": "Live TV",
  "Live Tv Categories": "Live Tv Categories",
  "Location information": "Location information",
  Logout: "Logout",
  "Logotype & Branding": "Logotype & Branding",
  "Logout Confirm": "Logout Confirm",

  // m
  Minute: "Minute",
  Model: "Model",
  Media: "Media",
  "Media package": "Media package",
  "Media packages": "Media packages",
  "Media Library": "Media Library",

  Messages: "Messages",
  Messaging: "Messaging",
  "Mobile Phone Number": "Mobile Phone Number",
  "Manage services": "Manage services",
  "Manage staff": "Manage staff",
  "Manage user accounts": "Manage user accounts",
  "Manage TV Channels": "Manage TV Channels",
  "Manage information about user accounts": "Manage information about user accounts",
  "Manage information about organization employees":
    "Manage information about organization employees",
  "Manage display of nearby airports": "Manage display of nearby airports",
  "Manage display of feedback parameters": "Manage display of feedback parameters",
  "Music Genres": "Music Genres",
  "Music deleted successfully": "Music deleted successfully",

  Movie: "Movie",
  Movies: "Movies",
  Music: "Music",
  Monitoring: "Monitoring",

  // n
  No: "No",
  "No products": "No products",
  "No services": "No services",
  Name: "Name",
  "Name of facility": "Name of facility",

  "No guest": "No guest",
  "No host": "No host",
  "No message": "No message",
  "New message": "New message",
  "New Messages": "New Messages",
  "No Comment": "No Comment",
  "No Reservation": "No Reservation",
  "N/A": "N/A",
  "No Email": "No Email",
  "No Phone": "No Phone",
  "No Quick Messages": "No Quick Messages",
  "No matched your search...": "No matched your search...",
  "Not active in chat...": "Not active in chat...",

  "Number of location to create": "Number of location to create",
  "New guest in room": "New guest in room",
  "News Expire Time": "News Expire Time",

  "New password": "New password",
  News: "News",
  "Notification icon": "Notification icon",
  "Notification sound": "Notification sound",

  // o

  "Old password": "Old password",

  Occupied: "Occupied",
  "Occupied rooms": "Occupied rooms",

  Organization: "Organization",
  Occupancy: "Occupancy",

  "Only free live tv": "Only free live tv",
  "Only free movies": "Only free movies",

  Ordered: "Ordered",
  Orders: "Orders",
  "Orders History": "Orders History",
  "Organization Info": "Organization Info",

  "Organization Information": "Organization Information",
  "Organization Information Settings": "Organization Information Settings",
  Online: "Online",
  Offline: "Offline",
  "Occupied locations": "Occupied locations",

  // p
  Package: "Package",
  Packages: "Packages",
  "Package name": "Package name",
  "Parental control": "Parental control",
  "Paid price sum": "Paid price sum",
  Price: "Price",
  Publish: "Publish",
  "Published Status": "Published Status",
  Password: "Password",
  Product: "Product",
  Products: "Products",
  "Product information": "Product information",
  "Primary language": "Primary language",
  Preference: "Preference",
  Promotions: "Promotions",
  "Price per day": "Price per day",
  "Price for month": "Price for month",
  Phone: "Phone",
  "Phone number": "Phone number",

  "Please input": "Please input",
  "Please input your": "Please input your",
  "Please select": "Please select",
  "Please choose": "Please choose",
  "Planned Departure Date": "Planned Departure Date",
  "Play Tv Channel": "Play Tv Channel",
  "Play Movie": "Play Movie",
  Play: "Play",
  Profile: "Profile",
  Player: "Player",
  "Please input name": "Please input name",

  PMS: "PMS",
  "Partial access": "Partial access",
  Poster: "Poster",
  Paid: "Paid",
  Pending: "Pending",
  "Please enter the genre name": "Please enter the genre name",
  "Passport Number": "Passport Number",
  "Price must be a number": "Price must be a number",
  // q
  "Do you want to delete": "Do you want to delete",
  Question: "Question",
  "QUICK TALK SETTINGS": "QUICK TALK SETTINGS",
  "Quick messages": "Quick messages",
  Quantity: "Quantity",
  "Question title": "Question title",

  "Queen Bed": "Queen Bed",

  // r

  "Payment Date": "Payment Date",
  "Payment ID": "Payment ID",
  "Payment Method": "Payment Method",

  Property: "Property",
  Properties: "Properties",
  "Property Page": "Property Page",
  "Property info updated successfully": "Property info updated successfully",
  "Property added successfully": "Property added successfully",
  "Property info deleted successfully": "Property info deleted successfully",
  "Property information": "Property information",

  // r
  RIS: "RIS",
  Roles: "Roles",
  Region: "Region",
  Room: "Room",
  Rooms: "Rooms",
  "Room number": "Room number",
  "Room information": "Room information",
  "Remember me": "Remember me",
  "Reservation No": "Reservation No",
  "Reservation Number": "Reservation Number",
  "Reset password": "Reset password",
  "Recent activity": "Recent activity",
  "Rejected Date": "Rejected Date",
  "Room price": "Room price",
  Rating: "Rating",
  "Rating pg": "Rating pg",
  Runtime: "Runtime",
  "Replace gallery": "Replace gallery",
  Reject: "Reject",
  Rejected: "Rejected",
  "Remove Selected": "Remove Selected",
  Remove: "Remove",
  Reserving: "For reserving events",
  Radio: "Radio",
  Radio_channels: "Radio Channels",
  "Radio Genre": "Radio Genre",
  "Radio updated successfully": "Radio updated successfully",
  "Radio added successfully": "Radio added successfully",
  "Radio deleted successfully": "Radio deleted successfully",
  "Reserved Date": "Reserved Date",

  "Room updated successfully": "Room updated successfully",
  "Room added successfully": "Room added successfully",
  "Room deleted successfully": "Room deleted successfully",

  // s
  Select: "Select",
  "Save changes": "Save changes",
  "Sign in": "Sign in",
  Surname: "Surname",
  Settings: "Settings",
  "Show columns": "Show columns",
  "Show Date": "Show Date",
  "Show City": "Show City",
  "Show Logo": "Show Logo",
  "Show weather": "Show weather",
  "Show picture": "Show picture",
  "Show welcome message": "Show welcome message",
  "Show welcome message once": "Show welcome message once",
  "Show welcome message position": "Show welcome message position",
  "Show News Promotions": "Show News Promotions",
  "Select filter parameters": "Select filter parameters",
  "Select orders parametrs": "Select orders parametrs",
  "Select orders table": "Select orders table",
  "Select info table": "Select info table",
  "Select info type": "Select info type",
  Season: "Season",
  Seasons: "Seasons",
  Service: "Service",
  Services: "Services",
  "Service Information": "Service Information",
  Search: "Search",
  "Search epg ...": "Search epg ...",
  "Search language ...": "Search language ...",
  "Search country ...": "Search country ...",
  "Search movies ...": "Search movies ...",
  State: "State",
  "Street address": "Street address",
  Status: "Status",
  Save: "Save",
  "Send messages": "Send messages",
  "Select a Conversation": "Select a Conversation",
  "Sorry, the page you visited does not exist":
    "Sorry, the page you visited does not exist",
  Size: "Size",
  "Screen Mirroring": "Screen Mirroring",
  Screen: "Screen",
  "Select Organization": "Select Organization",
  "Select all": "Select all",
  Skip: "Skip",
  Sending: "For sending events",
  "Select category": "Select category",
  Success: "Success",
  "Social Media": "Social Media",
  "Social Media links updated successfully": "Social Media links updated successfully",
  "Service Page": "Service Page",
  "Service info updated successfully": "Service info updated successfully",
  "Service added successfully": "Service added successfully",
  "Service info deleted successfully": "Service info deleted successfully",
  "Set facebook auth key": "Set facebook auth key",
  "Set google auth key": "Set google auth key",
  "Social auth configs": "Social auth configs",
  "Slider Page": "Slider Page",
  "Slider info updated successfully": "Slider info updated successfully",
  "Slider added successfully": "Slider added successfully",
  "Slider info deleted successfully": "Slider info deleted successfully",
  "Sofa Bed": "Sofa Bed",
  "Size must be a number": "Size must be a number",
  // t

  Text: "Text",

  "Tmdb movies": "Tmdb movies",
  "Total payments": "Total payments",

  Total: "Total",
  Title: "Title",
  "Tizen player": "Tizen player",
  TV: "TV",
  "TV name": "TV name",
  "Tv show": "Tv show",
  "The number of location is required and must be a number":
    "The number of location is required and must be a number",
  "TV Channel settngs": "TV Channel settngs",
  "Tv Channel": "Tv Channel",
  "Tv Channels": "Tv Channels",
  "Tv Application": "Tv Application",
  Type: "Type",

  "Tv rights": "Tv rights",
  "Total News": "Total News",
  "TV information": "TV information",
  "This is default package": "This is default package",
  "Tmdb id": "Tmdb id",

  "The file is downloading": "The file is downloading",

  Time: "Time",

  "Try selecting a conversation or searching for someone specific":
    "Try selecting a conversation or searching for someone specific",

  Translation: "Translation",
  "Time zone": "Time zone",
  Tags: "Tags",
  "Choose tags": "Choose tags",
  "Tag already exists": "Tag already exists",
  "Twin Beds": "Twin Beds",
  "This Field must be a number": "This Field must be a number",

  // u
  User: "User",
  Users: "Users",
  "User account": "User account",
  Url: "Url",
  "Udp url": "Udp url",
  "UDP address": "UDP address",
  "Uploading videos": "Uploading videos",
  "Useful Info": "Useful Info",
  Unpaid: "Unpaid",
  Unconfirmed: "Unconfirmed",
  Update: "Update",

  // v
  "View settings": "View settings",
  "Vacant & Ready": "Vacant & Ready",
  "Vacant rooms": "Vacant rooms",
  Vod: "Vod",
  "Vod Categories": "Vod Categories",
  "View Bill": "View Bill",
  Video: "Video",
  "Vacant locations": "Vacant locations",

  // w
  Widgets: "Widgets",
  Website: "Website",
  "Whole prict": "Whole prict",
  "Write a message": "Write a message",
  "Wether Edit": "Wether Edit",
  "Welcome message": "Welcome message",
  "Weather forecast": "Weather forecast",
  // x
  // y
  Yes: "Yes",
  Year: "Year",
  "You can use “,” for separate room numbers and “-” to create range of location ie 101-110":
    "You can use “,” for separate room numbers and “-” to create range of location ie 101-110",

  "You are online": "You are online",
  "You are offline": "You are offline",
  // z
  "Zip Code": "Zip Code",

  Key: "Key",
  "Help-info": "Help & info",
};
export default EN;
