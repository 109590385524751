import axios from "axios";

const request = (method, url, body, callback, errorCallback, notTimeout) => {
  let myAxios = axios.create();

  if (!notTimeout) {
    myAxios.defaults.timeout = 30000;
  }

  myAxios.defaults.headers.common["Authorization"] = localStorage.getItem("TOKEN");
  myAxios[method](url, method === "delete" ? { data: body } : body)
    .then((response) => {
      if (response.data.error) {
        if (
          response.data.message === "Network Error" ||
          response.data.message === "Forbidden" ||
          response.data.message === "Forbidden"
        ) {
          localStorage.clear();
          window.location.href = "#";
          return;
        }

        if (errorCallback) {
          errorCallback(response.data.message);
        }
      } else {
        callback(response.data.message);
      }
    })
    .catch((error) => {
      console.log(error.response);

      const resMessage = error?.response?.data?.message;

      if (resMessage === "Forbidden") {
        localStorage.clear();
        window.location.href = "/login";
        return;
      }

      if (errorCallback) {
        errorCallback(error.message);
      }
    });
};

export default request;
