import {
  SearchOutlined,
  InfoCircleOutlined,
  EllipsisOutlined,
  DownloadOutlined,
  ArrowLeftOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

const ICONS = {
  // a
  AIRPLANE: (
    <svg
      width="90"
      height="60"
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 12H23.8125L17.25 0.5625C17.125 0.25 16.6875 0 16.375 0H10.875C10.1875 0 9.75 0.6875 9.875 1.3125L13 12H9L6.25 8.4375C6.0625 8.1875 5.8125 8 5.5 8H2C1.3125 8 0.8125 8.625 1 9.25L3 16L1 22.8125C0.8125 23.4375 1.3125 24 2 24H5.5C5.8125 24 6.0625 23.875 6.25 23.625L9 20H13L9.875 30.75C9.75 31.375 10.1875 32 10.875 32H16.375C16.75 32 17.0625 31.8125 17.25 31.5L23.8125 20H31C33.1875 20 37 18.25 37 16C37 13.8125 33.1875 12 31 12ZM31 18H22.6875L15.8125 30H12.1875L15.625 18H8L5 22H3.3125L5.0625 16L3.3125 10H5L8 14H15.625L12.1875 2H15.8125L22.6875 14H31C32.625 14 34.875 15.375 35 16C34.875 16.6875 32.625 18 31 18Z"
        fill="#A1A7B8"
      />
    </svg>
  ),

  ARROW_RIGHT_OUTLINED: (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.38281 0.390625L4.91406 0.835937C4.79688 0.953124 4.79688 1.14062 4.91406 1.23437L8.54688 4.89062L0.531251 4.89062C0.390626 4.89062 0.250001 5.00781 0.250001 5.17187L0.250001 5.82812C0.250001 5.96875 0.390626 6.10937 0.531251 6.10937L8.54688 6.10937L4.91406 9.74219C4.79688 9.83594 4.79688 10.0234 4.91406 10.1406L5.38281 10.5859C5.47656 10.7031 5.66406 10.7031 5.78125 10.5859L10.6797 5.6875C10.7969 5.57031 10.7969 5.40625 10.6797 5.28906L5.78125 0.390625C5.66406 0.273437 5.47656 0.273437 5.38281 0.390625Z"
        fill="white"
      />
    </svg>
  ),

  ARROW_LEFT_OUTLINED: (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.61719 10.6094L6.08594 10.1641C6.20312 10.0469 6.20312 9.85938 6.08594 9.76562L2.45312 6.10938H10.4688C10.6094 6.10938 10.75 5.99219 10.75 5.82812V5.17188C10.75 5.03125 10.6094 4.89062 10.4688 4.89062H2.45312L6.08594 1.25781C6.20312 1.16406 6.20312 0.976562 6.08594 0.859375L5.61719 0.414062C5.52344 0.296875 5.33594 0.296875 5.21875 0.414062L0.320312 5.3125C0.203125 5.42969 0.203125 5.59375 0.320312 5.71094L5.21875 10.6094C5.33594 10.7266 5.52344 10.7266 5.61719 10.6094Z"
        fill="white"
      />
    </svg>
  ),

  ATTACH: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="18px"
      viewBox="0 0 24 24"
      width="18px"
      fill="#262626"
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <path d="M8,11h8v2H8V11z M20.1,12H22c0-2.76-2.24-5-5-5h-4v1.9h4C18.71,8.9,20.1,10.29,20.1,12z M3.9,12c0-1.71,1.39-3.1,3.1-3.1h4 V7H7c-2.76,0-5,2.24-5,5s2.24,5,5,5h4v-1.9H7C5.29,15.1,3.9,13.71,3.9,12z M19,12h-2v3h-3v2h3v3h2v-3h3v-2h-3V12z" />
      </g>
    </svg>
  ),
  ATTACH_WHITE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="18px"
      viewBox="0 0 24 24"
      width="18px"
      fill="#fff"
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <path d="M8,11h8v2H8V11z M20.1,12H22c0-2.76-2.24-5-5-5h-4v1.9h4C18.71,8.9,20.1,10.29,20.1,12z M3.9,12c0-1.71,1.39-3.1,3.1-3.1h4 V7H7c-2.76,0-5,2.24-5,5s2.24,5,5,5h4v-1.9H7C5.29,15.1,3.9,13.71,3.9,12z M19,12h-2v3h-3v2h3v3h2v-3h3v-2h-3V12z" />
      </g>
    </svg>
  ),

  ATTACH_FILE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18px"
      viewBox="0 0 24 24"
      width="18px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z" />
    </svg>
  ),

  ACCEPT: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30px"
      viewBox="0 0 49 49"
      width="30px"
    >
      <path
        d="M21.05 33.1 35.2 18.95 32.9 16.7 21.05 28.55 15.05 22.55 12.8 24.8ZM24 44Q19.75 44 16.1 42.475Q12.45 40.95 9.75 38.25Q7.05 35.55 5.525 31.9Q4 28.25 4 24Q4 19.8 5.525 16.15Q7.05 12.5 9.75 9.8Q12.45 7.1 16.1 5.55Q19.75 4 24 4Q28.2 4 31.85 5.55Q35.5 7.1 38.2 9.8Q40.9 12.5 42.45 16.15Q44 19.8 44 24Q44 28.25 42.45 31.9Q40.9 35.55 38.2 38.25Q35.5 40.95 31.85 42.475Q28.2 44 24 44ZM24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24ZM24 41Q31.25 41 36.125 36.125Q41 31.25 41 24Q41 16.75 36.125 11.875Q31.25 7 24 7Q16.75 7 11.875 11.875Q7 16.75 7 24Q7 31.25 11.875 36.125Q16.75 41 24 41Z"
        fill="#2a9d8f"
      />
    </svg>
  ),

  ADD_PERSON: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="32px"
      viewBox="0 0 70 60"
      width="32px"
      fill="#a7a7a7"
    >
      <path d="M36.5 28V21.5H30V18.5H36.5V12H39.5V18.5H46V21.5H39.5V28ZM18 23.95Q14.7 23.95 12.6 21.85Q10.5 19.75 10.5 16.45Q10.5 13.15 12.6 11.05Q14.7 8.95 18 8.95Q21.3 8.95 23.4 11.05Q25.5 13.15 25.5 16.45Q25.5 19.75 23.4 21.85Q21.3 23.95 18 23.95ZM2 40V35.3Q2 33.55 2.9 32.125Q3.8 30.7 5.4 30Q9.15 28.35 12.075 27.675Q15 27 18 27Q21 27 23.925 27.675Q26.85 28.35 30.55 30Q32.15 30.75 33.075 32.15Q34 33.55 34 35.3V40ZM5 37H31V35.3Q31 34.5 30.6 33.775Q30.2 33.05 29.35 32.7Q25.85 31 23.375 30.5Q20.9 30 18 30Q15.1 30 12.625 30.525Q10.15 31.05 6.6 32.7Q5.85 33.05 5.425 33.775Q5 34.5 5 35.3ZM18 20.95Q19.95 20.95 21.225 19.675Q22.5 18.4 22.5 16.45Q22.5 14.5 21.225 13.225Q19.95 11.95 18 11.95Q16.05 11.95 14.775 13.225Q13.5 14.5 13.5 16.45Q13.5 18.4 14.775 19.675Q16.05 20.95 18 20.95ZM18 16.45Q18 16.45 18 16.45Q18 16.45 18 16.45Q18 16.45 18 16.45Q18 16.45 18 16.45Q18 16.45 18 16.45Q18 16.45 18 16.45Q18 16.45 18 16.45Q18 16.45 18 16.45ZM18 30Q18 30 18 30Q18 30 18 30Q18 30 18 30Q18 30 18 30Q18 30 18 30Q18 30 18 30Q18 30 18 30Q18 30 18 30Z" />
    </svg>
  ),
  // b
  BALANCE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <path d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,20c-4.41,0-8-3.59-8-8c0-4.41,3.59-8,8-8 s8,3.59,8,8C20,16.41,16.41,20,12,20z M12.89,11.1c-1.78-0.59-2.64-0.96-2.64-1.9c0-1.02,1.11-1.39,1.81-1.39 c1.31,0,1.79,0.99,1.9,1.34l1.58-0.67c-0.15-0.44-0.82-1.91-2.66-2.23V5h-1.75v1.26c-2.6,0.56-2.62,2.85-2.62,2.96 c0,2.27,2.25,2.91,3.35,3.31c1.58,0.56,2.28,1.07,2.28,2.03c0,1.13-1.05,1.61-1.98,1.61c-1.82,0-2.34-1.87-2.4-2.09L8.1,14.75 c0.63,2.19,2.28,2.78,3.02,2.96V19h1.75v-1.24c0.52-0.09,3.02-0.59,3.02-3.22C15.9,13.15,15.29,11.93,12.89,11.1z" />
      </g>
    </svg>
  ),

  // c
  CHECK: (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1953 0.46875L4.03125 6.63281L1.78125 4.35938C1.66406 4.26562 1.47656 4.26562 1.38281 4.35938L0.703125 5.03906C0.609375 5.13281 0.609375 5.32031 0.703125 5.4375L3.84375 8.55469C3.96094 8.67188 4.125 8.67188 4.24219 8.55469L11.2734 1.52344C11.3672 1.42969 11.3672 1.24219 11.2734 1.125L10.5938 0.46875C10.5 0.351562 10.3125 0.351562 10.1953 0.46875Z"
        fill="white"
      />
    </svg>
  ),
  LANGUAGES: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // height="48"
      // width="48"
      height="48px"
      viewBox="0 0 48 48"
      width="48px"
      fill="#A1A7B8"
    >
      <path d="M24 44q-4.2 0-7.85-1.575Q12.5 40.85 9.8 38.15q-2.7-2.7-4.25-6.375Q4 28.1 4 23.9t1.55-7.825Q7.1 12.45 9.8 9.75t6.35-4.225Q19.8 4 24 4q4.2 0 7.85 1.525Q35.5 7.05 38.2 9.75q2.7 2.7 4.25 6.325Q44 19.7 44 23.9t-1.55 7.875Q40.9 35.45 38.2 38.15t-6.35 4.275Q28.2 44 24 44Zm7.9-27.5h7.5q-1.65-3.45-4.525-5.75Q32 8.45 28.25 7.5q1.25 1.85 2.125 4t1.525 5Zm-12.7 0h9.7q-.55-2.65-1.85-5.125T24 7q-1.6 1.35-2.7 3.55-1.1 2.2-2.1 5.95ZM7.6 28.45h7.95q-.15-1.35-.175-2.425-.025-1.075-.025-2.125 0-1.25.05-2.225.05-.975.2-2.175h-8q-.35 1.2-.475 2.15T7 23.9q0 1.3.125 2.325.125 1.025.475 2.225ZM19.75 40.5q-1.25-1.9-2.15-4.1-.9-2.2-1.5-4.95H8.6Q10.5 35 13 37.025q2.5 2.025 6.75 3.475ZM8.6 16.5h7.55q.55-2.7 1.4-4.825.85-2.125 2.15-4.125-3.75.95-6.55 3.2T8.6 16.5ZM24 41.1q1.75-1.8 2.925-4.125Q28.1 34.65 28.85 31.45H19.2q.7 3 1.875 5.4Q22.25 39.25 24 41.1Zm-5.35-12.65H29.4q.2-1.55.25-2.525.05-.975.05-2.025 0-1-.05-1.925T29.4 19.5H18.65q-.2 1.55-.25 2.475-.05.925-.05 1.925 0 1.05.05 2.025.05.975.25 2.525Zm9.65 12q3.6-1.15 6.475-3.45 2.875-2.3 4.625-5.55h-7.45q-.65 2.7-1.525 4.9-.875 2.2-2.125 4.1Zm4.1-12h8q.35-1.2.475-2.225Q41 25.2 41 23.9q0-1.3-.125-2.25T40.4 19.5h-7.95q.15 1.75.2 2.675.05.925.05 1.725 0 1.1-.075 2.075-.075.975-.225 2.475Z" />
    </svg>
  ),
  COUNTRIES: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 -960 960 960"
      width="48"
      fill="#A1A7B8"
    >
      <path
        d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-43-61v-82q-35 0-59-26t-24-61v-44L149-559q-5 20-7 39.5t-2 39.5q0 130 84.5 227T437-141Zm294-108q22-24 38.5-51t28-56.5q11.5-29.5 17-60.5t5.5-63q0-106-58-192.5T607-799v18q0 35-24 61t-59 26h-87v87q0 17-13.5 28T393-568h-83v88h258q17 0 28 13t11 30v127h43q29 0 51 17t30 44Z"
        fill="#A1A7B8"
      />
    </svg>
  ),
  COPY: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path d="M760-200H320q-33 0-56.5-23.5T240-280v-560q0-33 23.5-56.5T320-920h280l240 240v400q0 33-23.5 56.5T760-200ZM560-640v-200H320v560h440v-360H560ZM160-40q-33 0-56.5-23.5T80-120v-560h80v560h440v80H160Zm160-800v200-200 560-560Z" />
    </svg>
  ),

  CANCEL: (
    <svg
      stroke="currentColor"
      fill="none"
      stroke-width="2"
      viewBox="0 0 24 24"
      stroke-linecap="round"
      stroke-linejoin="round"
      height="24px"
      width="24px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.5 21h-6.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v5"></path>
      <path d="M16 3v4"></path>
      <path d="M8 3v4"></path>
      <path d="M4 11h16"></path>
      <path d="M19 19m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
      <path d="M17 21l4 -4"></path>
    </svg>
  ),

  // d
  DELETE: (
    <svg
      width="15"
      height="15"
      viewBox="0 0 12 12"
      fill="#262626"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.03125 9.75H7.59375C7.73438 9.75 7.875 9.63281 7.875 9.46875L7.875 4.40625C7.875 4.26562 7.73438 4.125 7.59375 4.125H7.03125C6.86719 4.125 6.75 4.26562 6.75 4.40625L6.75 9.46875C6.75 9.63281 6.86719 9.75 7.03125 9.75ZM10.875 1.875H8.92969L8.13281 0.5625C7.94531 0.257812 7.52344 0 7.17188 0L4.80469 0C4.45313 0 4.03125 0.257812 3.84375 0.5625L3.04688 1.875H1.125C0.914063 1.875 0.75 2.0625 0.75 2.25L0.75 2.625C0.75 2.83594 0.914063 3 1.125 3H1.5L1.5 10.875C1.5 11.5078 1.99219 12 2.625 12H9.375C9.98438 12 10.5 11.5078 10.5 10.875L10.5 3H10.875C11.0625 3 11.25 2.83594 11.25 2.625V2.25C11.25 2.0625 11.0625 1.875 10.875 1.875ZM4.75781 1.19531C4.78125 1.17188 4.85156 1.125 4.875 1.125H4.89844L7.10156 1.125C7.125 1.125 7.19531 1.17187 7.21875 1.19531L7.61719 1.875L4.35938 1.875L4.75781 1.19531ZM9.375 10.875H2.625L2.625 3L9.375 3L9.375 10.875ZM4.40625 9.75H4.96875C5.10938 9.75 5.25 9.63281 5.25 9.46875L5.25 4.40625C5.25 4.26562 5.10938 4.125 4.96875 4.125H4.40625C4.24219 4.125 4.125 4.26562 4.125 4.40625L4.125 9.46875C4.125 9.63281 4.24219 9.75 4.40625 9.75Z"
        fill="#262626"
      />
    </svg>
  ),
  DELETE_WHITE: (
    <svg width="18" height="18" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.03125 9.75H7.59375C7.73438 9.75 7.875 9.63281 7.875 9.46875L7.875 4.40625C7.875 4.26562 7.73438 4.125 7.59375 4.125H7.03125C6.86719 4.125 6.75 4.26562 6.75 4.40625L6.75 9.46875C6.75 9.63281 6.86719 9.75 7.03125 9.75ZM10.875 1.875H8.92969L8.13281 0.5625C7.94531 0.257812 7.52344 0 7.17188 0L4.80469 0C4.45313 0 4.03125 0.257812 3.84375 0.5625L3.04688 1.875H1.125C0.914063 1.875 0.75 2.0625 0.75 2.25L0.75 2.625C0.75 2.83594 0.914063 3 1.125 3H1.5L1.5 10.875C1.5 11.5078 1.99219 12 2.625 12H9.375C9.98438 12 10.5 11.5078 10.5 10.875L10.5 3H10.875C11.0625 3 11.25 2.83594 11.25 2.625V2.25C11.25 2.0625 11.0625 1.875 10.875 1.875ZM4.75781 1.19531C4.78125 1.17188 4.85156 1.125 4.875 1.125H4.89844L7.10156 1.125C7.125 1.125 7.19531 1.17187 7.21875 1.19531L7.61719 1.875L4.35938 1.875L4.75781 1.19531ZM9.375 10.875H2.625L2.625 3L9.375 3L9.375 10.875ZM4.40625 9.75H4.96875C5.10938 9.75 5.25 9.63281 5.25 9.46875L5.25 4.40625C5.25 4.26562 5.10938 4.125 4.96875 4.125H4.40625C4.24219 4.125 4.125 4.26562 4.125 4.40625L4.125 9.46875C4.125 9.63281 4.24219 9.75 4.40625 9.75Z"
        fill="#fff"
      />
    </svg>
  ),

  DOWNLOAD: <DownloadOutlined />,

  DONE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="15px"
      viewBox="0 0 24 24"
      width="15px"
      fill="#1890ff"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
    </svg>
  ),

  DONE_ALL: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="15px"
      viewBox="0 0 24 24"
      width="15px"
      fill="#1890ff"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M18 7l-1.41-1.41-6.34 6.34 1.41 1.41L18 7zm4.24-1.41L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM.41 13.41L6 19l1.41-1.41L1.83 12 .41 13.41z" />
    </svg>
  ),

  // e
  EDIT: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18px"
      viewBox="0 0 24 24"
      width="18px"
      fill="#262626"
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <g>
            <path d="M3,21l3.75,0L17.81,9.94l-3.75-3.75L3,17.25L3,21z M5,18.08l9.06-9.06l0.92,0.92L5.92,19L5,19L5,18.08z" />
          </g>
          <g>
            <path d="M18.37,3.29c-0.39-0.39-1.02-0.39-1.41,0l-1.83,1.83l3.75,3.75l1.83-1.83c0.39-0.39,0.39-1.02,0-1.41L18.37,3.29z" />
          </g>
        </g>
      </g>
    </svg>
  ),

  EDIT_WHITE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18px"
      viewBox="0 0 24 24"
      width="18px"
      fill="white"
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <g>
            <path d="M3,21l3.75,0L17.81,9.94l-3.75-3.75L3,17.25L3,21z M5,18.08l9.06-9.06l0.92,0.92L5.92,19L5,19L5,18.08z" />
          </g>
          <g>
            <path d="M18.37,3.29c-0.39-0.39-1.02-0.39-1.41,0l-1.83,1.83l3.75,3.75l1.83-1.83c0.39-0.39,0.39-1.02,0-1.41L18.37,3.29z" />
          </g>
        </g>
      </g>
    </svg>
  ),
  ELLIPSIS: <EllipsisOutlined />,

  EMPTY: (
    <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0 1)" fill="none">
        <ellipse cx="32" cy="33" rx="32" ry="7"></ellipse>
        <g className="ant-empty-img-simple-g">
          <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
          <path
            d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
            className="ant-empty-img-simple-path"
          ></path>
        </g>
      </g>
    </svg>
  ),
  // f
  FILTER: (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2773 0.25H2.6875C1.17578 0.25 0.402344 2.07812 1.49219 3.13281L7.1875 8.82812V14.875C7.1875 15.4375 7.43359 15.9297 7.85547 16.2812L10.1055 17.9688C11.1953 18.707 12.8125 18.0039 12.8125 16.5977V8.82812L18.4727 3.13281C19.5625 2.07812 18.7891 0.25 17.2773 0.25ZM11.125 8.125V16.5625L8.875 14.875V8.125L2.6875 1.9375H17.3125L11.125 8.125Z"
        fill="#A1A7B8"
      />
    </svg>
  ),

  FEEDBACK_SETTINGS: (
    <svg
      width="90"
      height="60"
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 0H4C1.75 0 0 1.8125 0 4V22C0 24.25 1.75 26 4 26H10V31.25C10 31.75 10.3125 32 10.75 32C10.875 32 11 32 11.1875 31.875L19 26H28C30.1875 26 32 24.25 32 22V4C32 1.8125 30.1875 0 28 0ZM30 22C30 23.125 29.0625 24 28 24H18.3125L17.75 24.4375L12 28.75V24H4C2.875 24 2 23.125 2 22V4C2 2.9375 2.875 2 4 2H28C29.0625 2 30 2.9375 30 4V22ZM20.6875 14.875C19.5625 16.25 17.8125 17 16 17C14.125 17 12.4375 16.25 11.25 14.875C10.875 14.4375 10.25 14.375 9.8125 14.75C9.375 15.125 9.375 15.75 9.6875 16.1875C11.25 18 13.5625 19 16 19C18.375 19 20.6875 18 22.25 16.1875C22.625 15.75 22.5625 15.125 22.125 14.75C21.6875 14.375 21.0625 14.4375 20.6875 14.875ZM12 11.5C12.8125 11.5 13.5 10.875 13.5 10C13.5 9.1875 12.8125 8.5 12 8.5C11.125 8.5 10.5 9.1875 10.5 10C10.5 10.875 11.125 11.5 12 11.5ZM20 11.5C20.8125 11.5 21.5 10.875 21.5 10C21.5 9.1875 20.8125 8.5 20 8.5C19.125 8.5 18.5 9.1875 18.5 10C18.5 10.875 19.125 11.5 20 11.5Z"
        fill="#A1A7B8"
      />
    </svg>
  ),

  FACEBOOK: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="70"
      height="50"
      viewBox="0 0 50 50"
    >
      <path d="M 9 4 C 6.2504839 4 4 6.2504839 4 9 L 4 41 C 4 43.749516 6.2504839 46 9 46 L 25.832031 46 A 1.0001 1.0001 0 0 0 26.158203 46 L 31.832031 46 A 1.0001 1.0001 0 0 0 32.158203 46 L 41 46 C 43.749516 46 46 43.749516 46 41 L 46 9 C 46 6.2504839 43.749516 4 41 4 L 9 4 z M 9 6 L 41 6 C 42.668484 6 44 7.3315161 44 9 L 44 41 C 44 42.668484 42.668484 44 41 44 L 33 44 L 33 30 L 36.820312 30 L 38.220703 23 L 33 23 L 33 21 C 33 20.442508 33.05305 20.398929 33.240234 20.277344 C 33.427419 20.155758 34.005822 20 35 20 L 38 20 L 38 14.369141 L 37.429688 14.097656 C 37.429688 14.097656 35.132647 13 32 13 C 29.75 13 27.901588 13.896453 26.71875 15.375 C 25.535912 16.853547 25 18.833333 25 21 L 25 23 L 22 23 L 22 30 L 25 30 L 25 44 L 9 44 C 7.3315161 44 6 42.668484 6 41 L 6 9 C 6 7.3315161 7.3315161 6 9 6 z M 32 15 C 34.079062 15 35.38736 15.458455 36 15.701172 L 36 18 L 35 18 C 33.849178 18 32.926956 18.0952 32.150391 18.599609 C 31.373826 19.104024 31 20.061492 31 21 L 31 25 L 35.779297 25 L 35.179688 28 L 31 28 L 31 44 L 27 44 L 27 28 L 24 28 L 24 25 L 27 25 L 27 21 C 27 19.166667 27.464088 17.646453 28.28125 16.625 C 29.098412 15.603547 30.25 15 32 15 z"></path>
    </svg>
  ),

  // g
  GALLERIES: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18px"
      viewBox="0 0 24 24"
      width="18px"
      fill="#262626"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M20 4v12H8V4h12m0-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 9.67l1.69 2.26 2.48-3.1L19 15H9zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z" />
    </svg>
  ),
  GUI: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="60px"
      viewBox="0 0 24 24"
      width="90px"
      fill="#A1A7B8"
    >
      <path d="M2.53 19.65l1.34.56v-9.03l-2.43 5.86c-.41 1.02.08 2.19 1.09 2.61zm19.5-3.7L17.07 3.98c-.31-.75-1.04-1.21-1.81-1.23-.26 0-.53.04-.79.15L7.1 5.95c-.75.31-1.21 1.03-1.23 1.8-.01.27.04.54.15.8l4.96 11.97c.31.76 1.05 1.22 1.83 1.23.26 0 .52-.05.77-.15l7.36-3.05c1.02-.42 1.51-1.59 1.09-2.6zm-9.2 3.8L7.87 7.79l7.35-3.04h.01l4.95 11.95-7.35 3.05z" />
      <circle cx="11" cy="9" r="1" />
      <path d="M5.88 19.75c0 1.1.9 2 2 2h1.45l-3.45-8.34v6.34z" />
    </svg>
  ),

  GO_BACK: <ArrowLeftOutlined />,

  GRID_VIEW: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 50 50"
      fill="none"
    >
      <path
        d="M6 22.5V6H22.5V22.5ZM6 42V25.5H22.5V42ZM25.5 22.5V6H42V22.5ZM25.5 42V25.5H42V42ZM9 19.5H19.5V9H9ZM28.5 19.5H39V9H28.5ZM28.5 39H39V28.5H28.5ZM9 39H19.5V28.5H9ZM28.5 19.5ZM28.5 28.5ZM19.5 28.5ZM19.5 19.5Z"
        fill="#A1A7B8"
      />
    </svg>
  ),
  GOOGLE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="70"
      height="50"
      viewBox="0 0 50 50"
    >
      <path d="M 26 2 C 13.308594 2 3 12.308594 3 25 C 3 37.691406 13.308594 48 26 48 C 35.917969 48 41.972656 43.4375 45.125 37.78125 C 48.277344 32.125 48.675781 25.480469 47.71875 20.9375 L 47.53125 20.15625 L 46.75 20.15625 L 26 20.125 L 25 20.125 L 25 30.53125 L 36.4375 30.53125 C 34.710938 34.53125 31.195313 37.28125 26 37.28125 C 19.210938 37.28125 13.71875 31.789063 13.71875 25 C 13.71875 18.210938 19.210938 12.71875 26 12.71875 C 29.050781 12.71875 31.820313 13.847656 33.96875 15.6875 L 34.6875 16.28125 L 41.53125 9.4375 L 42.25 8.6875 L 41.5 8 C 37.414063 4.277344 31.960938 2 26 2 Z M 26 4 C 31.074219 4 35.652344 5.855469 39.28125 8.84375 L 34.46875 13.65625 C 32.089844 11.878906 29.199219 10.71875 26 10.71875 C 18.128906 10.71875 11.71875 17.128906 11.71875 25 C 11.71875 32.871094 18.128906 39.28125 26 39.28125 C 32.550781 39.28125 37.261719 35.265625 38.9375 29.8125 L 39.34375 28.53125 L 27 28.53125 L 27 22.125 L 45.84375 22.15625 C 46.507813 26.191406 46.066406 31.984375 43.375 36.8125 C 40.515625 41.9375 35.320313 46 26 46 C 14.386719 46 5 36.609375 5 25 C 5 13.390625 14.386719 4 26 4 Z"></path>
    </svg>
  ),
  // h
  HOTEL: (
    <svg
      width="90"
      height="60"
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.75 14H27.1875C27.5625 14 28 13.625 28 13.25V10.8125C28 10.4375 27.5625 10 27.1875 10H24.75C24.375 10 24 10.4375 24 10.8125V13.25C24 13.625 24.375 14 24.75 14ZM16.75 8H19.1875C19.5625 8 20 7.625 20 7.25V4.8125C20 4.4375 19.5625 4 19.1875 4H16.75C16.375 4 16 4.4375 16 4.8125V7.25C16 7.625 16.375 8 16.75 8ZM24.75 8H27.1875C27.5625 8 28 7.625 28 7.25V4.8125C28 4.4375 27.5625 4 27.1875 4H24.75C24.375 4 24 4.4375 24 4.8125V7.25C24 7.625 24.375 8 24.75 8ZM8.75 8H11.1875C11.5625 8 12 7.625 12 7.25V4.8125C12 4.4375 11.5625 4 11.1875 4H8.75C8.375 4 8 4.4375 8 4.8125V7.25C8 7.625 8.375 8 8.75 8ZM16.75 14H19.1875C19.5625 14 20 13.625 20 13.25V10.8125C20 10.4375 19.5625 10 19.1875 10H16.75C16.375 10 16 10.4375 16 10.8125V13.25C16 13.625 16.375 14 16.75 14ZM35.5 2C35.75 2 36 1.8125 36 1.5V0.5C36 0.25 35.75 0 35.5 0H0.5C0.1875 0 0 0.25 0 0.5V1.5C0 1.8125 0.1875 2 0.5 2H1.9375V30H0.5C0.1875 30 0 30.25 0 30.5V31.5C0 31.8125 0.1875 32 0.5 32H35.5C35.75 32 36 31.8125 36 31.5V30.5C36 30.25 35.75 30 35.5 30H34V2H35.5ZM20 30H16V25C16 24.5 16.4375 24 17 24H19C19.5 24 20 24.5 20 25V30ZM32 30H22V25C22 23.375 20.625 22 19 22H17C15.3125 22 14 23.375 14 25V30H3.9375V2H32V30ZM8.75 14H11.1875C11.5625 14 12 13.625 12 13.25V10.8125C12 10.4375 11.5625 10 11.1875 10H8.75C8.375 10 8 10.4375 8 10.8125V13.25C8 13.625 8.375 14 8.75 14ZM10.4375 23.875L11.4375 24C11.6875 24.0625 11.9375 23.875 12 23.625C12.625 20.9375 15.125 19 18 19C20.8125 19 23.3125 20.9375 23.9375 23.625C24 23.875 24.25 24.0625 24.5 24L25.5 23.875C25.8125 23.875 26 23.5625 25.9375 23.3125C25.1875 19.6875 21.8125 17 18 17C14.125 17 10.75 19.6875 10 23.3125C9.9375 23.5625 10.125 23.875 10.4375 23.875Z"
        fill="#A1A7B8"
      />
    </svg>
  ),
  // i
  INFO: <InfoCircleOutlined />,
  // j
  // k
  // l
  LOGO: (
    <svg
      width="90"
      height="60"
      viewBox="0 0 44 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.5 0H27.5V6.5H0.5V0Z" fill="#7367C1" />
      <rect x="30" width="13.5" height="6.5" fill="#E257AF" />
      <rect x="30" y="17.5" width="13.5" height="6.5" fill="#FFB05B" />
      <rect x="16.5" y="8.5" width="27" height="6.5" fill="#4FC2E6" />
      <rect x="0.5" y="8.5" width="13.5" height="6.5" fill="#57BE8F" />
      <path d="M0.5 17.5H27.5V24H0.5V17.5Z" fill="#F14949" />
    </svg>
  ),

  LOGOUT: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="15px"
      viewBox="0 0 24 24"
      width="15px"
      fill="#bc4749"
    >
      <g>
        <path d="M0,0h24v24H0V0z" fill="none" />
      </g>
      <g>
        <path d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z" />
      </g>
    </svg>
  ),

  LIST_VIEW: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 50 50"
      fill="none"
    >
      <path
        d="M8.55 26.5Q7.5 26.5 6.75 25.775Q6 25.05 6 24Q6 22.95 6.75 22.225Q7.5 21.5 8.55 21.5Q9.55 21.5 10.275 22.25Q11 23 11 24Q11 25 10.275 25.75Q9.55 26.5 8.55 26.5ZM8.5 14Q7.45 14 6.725 13.275Q6 12.55 6 11.5Q6 10.45 6.725 9.725Q7.45 9 8.5 9Q9.55 9 10.275 9.725Q11 10.45 11 11.5Q11 12.55 10.275 13.275Q9.55 14 8.5 14ZM8.55 39Q7.5 39 6.75 38.275Q6 37.55 6 36.55Q6 35.5 6.75 34.75Q7.5 34 8.55 34Q9.55 34 10.275 34.75Q11 35.5 11 36.55Q11 37.55 10.275 38.275Q9.55 39 8.55 39ZM16 38V35H42V38ZM16 25.5V22.5H42V25.5ZM16 13V10H42V13Z"
        fill="#A1A7B8"
      />
    </svg>
  ),

  // m
  MESSAGE: <i className="fas fa-envelope" />,
  MESSAGE_SVG: (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.75 0H2.25C0.984375 0 0 1.03125 0 2.25V15.75C0 17.0156 0.984375 18 2.25 18H21.75C22.9688 18 24 17.0156 24 15.75V2.25C24 1.03125 22.9688 0 21.75 0ZM2.25 1.5H21.75C22.125 1.5 22.5 1.875 22.5 2.25V4.21875C21.4688 5.0625 19.9688 6.28125 15.4219 9.89062C14.625 10.5469 13.0781 12.0469 12 12C10.875 12.0469 9.32812 10.5469 8.53125 9.89062C3.98438 6.28125 2.48438 5.0625 1.5 4.21875V2.25C1.5 1.875 1.82812 1.5 2.25 1.5ZM21.75 16.5H2.25C1.82812 16.5 1.5 16.1719 1.5 15.75V6.14062C2.53125 7.03125 4.21875 8.39062 7.59375 11.0625C8.57812 11.8594 10.2656 13.5469 12 13.5C13.6875 13.5469 15.375 11.8594 16.3594 11.0625C19.7344 8.39062 21.4219 7.03125 22.5 6.14062V15.75C22.5 16.1719 22.125 16.5 21.75 16.5Z"
        fill="#A1A7B8"
      />
    </svg>
  ),

  MEDIA: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M2 6H0v5h.01L0 20c0 1.1.9 2 2 2h18v-2H2V6zm5 9h14l-3.5-4.5-2.5 3.01L11.5 9zM22 4h-8l-2-2H6c-1.1 0-1.99.9-1.99 2L4 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 12H6V4h5.17l1.41 1.41.59.59H22v10z" />
    </svg>
  ),
  // n
  NOSEARCH: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#a7a7a7"
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <path d="M15.5,14h-0.79l-0.28-0.27C15.41,12.59,16,11.11,16,9.5C16,5.91,13.09,3,9.5,3C6.08,3,3.28,5.64,3.03,9h2.02 C5.3,6.75,7.18,5,9.5,5C11.99,5,14,7.01,14,9.5S11.99,14,9.5,14c-0.17,0-0.33-0.03-0.5-0.05v2.02C9.17,15.99,9.33,16,9.5,16 c1.61,0,3.09-0.59,4.23-1.57L14,14.71v0.79l5,4.99L20.49,19L15.5,14z" />
          <polygon points="6.47,10.82 4,13.29 1.53,10.82 0.82,11.53 3.29,14 0.82,16.47 1.53,17.18 4,14.71 6.47,17.18 7.18,16.47 4.71,14 7.18,11.53" />
        </g>
      </g>
    </svg>
  ),
  // o
  ORDERS: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="18" height="18">
      <path
        d="M256 0v128h128L256 0zM288 256H96v64h192V256zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM64 72C64 67.63 67.63 64 72 64h80C156.4 64 160 67.63 160 72v16C160 92.38 156.4 96 152 96h-80C67.63 96 64 92.38 64 88V72zM64 136C64 131.6 67.63 128 72 128h80C156.4 128 160 131.6 160 136v16C160 156.4 156.4 160 152 160h-80C67.63 160 64 156.4 64 152V136zM320 440c0 4.375-3.625 8-8 8h-80C227.6 448 224 444.4 224 440v-16c0-4.375 3.625-8 8-8h80c4.375 0 8 3.625 8 8V440zM320 240v96c0 8.875-7.125 16-16 16h-224C71.13 352 64 344.9 64 336v-96C64 231.1 71.13 224 80 224h224C312.9 224 320 231.1 320 240z"
        fill="#262626"
      />
    </svg>
  ),
  // p
  PLUS: (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.625 3.75H5.25V0.375C5.25 0.1875 5.0625 0 4.875 0H4.125C3.91406 0 3.75 0.1875 3.75 0.375V3.75H0.375C0.164062 3.75 0 3.9375 0 4.125V4.875C0 5.08594 0.164062 5.25 0.375 5.25H3.75V8.625C3.75 8.83594 3.91406 9 4.125 9H4.875C5.0625 9 5.25 8.83594 5.25 8.625V5.25H8.625C8.8125 5.25 9 5.08594 9 4.875V4.125C9 3.9375 8.8125 3.75 8.625 3.75Z"
        fill="white"
      />
    </svg>
  ),

  PLAY: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="youtube"
      className="svg-inline--fa fa-youtube fa-w-18"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      width="20px"
      height="24px"
    >
      <path
        fill="currentColor"
        d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
      ></path>
    </svg>
  ),

  PREFERENCE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="60px"
      viewBox="0 0 24 24"
      width="90px"
      fill="none"
    >
      <path
        fill="#A1A7B8"
        d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
      />
    </svg>
  ),
  // q
  QUESTION: <i className="fas fa-question" />,
  // r
  REJECT: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30px"
      viewBox="0 0 49 49"
      width="30px"
    >
      <path
        d="M16.5 33.6 24 26.1 31.5 33.6 33.6 31.5 26.1 24 33.6 16.5 31.5 14.4 24 21.9 16.5 14.4 14.4 16.5 21.9 24 14.4 31.5ZM24 44Q19.75 44 16.1 42.475Q12.45 40.95 9.75 38.25Q7.05 35.55 5.525 31.9Q4 28.25 4 24Q4 19.8 5.525 16.15Q7.05 12.5 9.75 9.8Q12.45 7.1 16.1 5.55Q19.75 4 24 4Q28.2 4 31.85 5.55Q35.5 7.1 38.2 9.8Q40.9 12.5 42.45 16.15Q44 19.8 44 24Q44 28.25 42.45 31.9Q40.9 35.55 38.2 38.25Q35.5 40.95 31.85 42.475Q28.2 44 24 44ZM24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24ZM24 41Q31 41 36 36Q41 31 41 24Q41 17 36 12Q31 7 24 7Q17 7 12 12Q7 17 7 24Q7 31 12 36Q17 41 24 41Z"
        fill="#ad3d3f"
      />
    </svg>
  ),
  // s
  SEND: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.75 1.21875L1.75 8.71875C0.65625 9.34375 0.75 10.9375 1.90625 11.4062L5.5 12.875V15.5C5.5 16.9688 7.34375 17.5625 8.1875 16.4062L9.5625 14.5625L13.0625 16C13.25 16.0938 13.4375 16.125 13.625 16.125C13.875 16.125 14.125 16.0625 14.375 15.9375C14.7812 15.6875 15.0312 15.3125 15.125 14.8438L16.9688 2.75C17.1562 1.5 15.8125 0.59375 14.75 1.21875ZM7 15.5V13.5L8.125 13.9688L7 15.5ZM13.625 14.625L8.8125 12.625L13.2188 6.3125C13.5312 5.8125 12.9062 5.25 12.4688 5.65625L5.84375 11.4062L2.5 10L15.5 2.5L13.625 14.625Z"
        fill="#2F6BFF"
      />
    </svg>
  ),
  SAVE: (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1953 0.46875L4.03125 6.63281L1.78125 4.35938C1.66406 4.26562 1.47656 4.26562 1.38281 4.35938L0.703125 5.03906C0.609375 5.13281 0.609375 5.32031 0.703125 5.4375L3.84375 8.55469C3.96094 8.67188 4.125 8.67188 4.24219 8.55469L11.2734 1.52344C11.3672 1.42969 11.3672 1.24219 11.2734 1.125L10.5938 0.46875C10.5 0.351562 10.3125 0.351562 10.1953 0.46875Z"
        fill="white"
      />
    </svg>
  ),
  SEARCH: (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8594 16.7383L13.6055 12.4844C13.5 12.4141 13.3945 12.3438 13.2891 12.3438H12.832C13.9219 11.0781 14.625 9.39062 14.625 7.5625C14.625 3.55469 11.3203 0.25 7.3125 0.25C3.26953 0.25 0 3.55469 0 7.5625C0 11.6055 3.26953 14.875 7.3125 14.875C9.14062 14.875 10.793 14.207 12.0938 13.1172V13.5742C12.0938 13.6797 12.1289 13.7852 12.1992 13.8906L16.4531 18.1445C16.6289 18.3203 16.9102 18.3203 17.0508 18.1445L17.8594 17.3359C18.0352 17.1953 18.0352 16.9141 17.8594 16.7383ZM7.3125 13.1875C4.18359 13.1875 1.6875 10.6914 1.6875 7.5625C1.6875 4.46875 4.18359 1.9375 7.3125 1.9375C10.4062 1.9375 12.9375 4.46875 12.9375 7.5625C12.9375 10.6914 10.4062 13.1875 7.3125 13.1875Z"
        fill="#A1A7B8"
      />
    </svg>
  ),
  SEARCHOUTLINED: <SearchOutlined />,

  SERVICES: (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.625 16.5H22.5V13.5C22.5 7.96875 18.1875 3.46875 12.75 3.04688V1.5H14.625C14.8125 1.5 15 1.35938 15 1.125V0.375C15 0.1875 14.8125 0 14.625 0H9.375C9.14062 0 9 0.1875 9 0.375V1.125C9 1.35938 9.14062 1.5 9.375 1.5H11.25V3.04688C5.76562 3.46875 1.5 7.96875 1.5 13.5V16.5H0.375C0.140625 16.5 0 16.6875 0 16.875V17.625C0 17.8594 0.140625 18 0.375 18H23.625C23.8125 18 24 17.8594 24 17.625V16.875C24 16.6875 23.8125 16.5 23.625 16.5ZM21 16.5H3V13.5C3 8.8125 6.65625 4.875 11.3438 4.54688C12.1406 4.5 11.8125 4.5 12.6094 4.54688C17.2969 4.875 21 8.8125 21 13.5V16.5Z"
        fill="#A1A7B8"
      />
    </svg>
  ),

  SETTINGS: (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5234 12.8438L16.8438 11.9062C17.0391 10.9688 17.0391 10.0703 16.8438 9.13281L18.5234 8.19531C18.7188 8.07812 18.7969 7.84375 18.7188 7.64844C18.2891 6.24219 17.5469 4.99219 16.6094 3.9375C16.4531 3.78125 16.2188 3.74219 16.0234 3.85938L14.3438 4.79688C13.6406 4.21094 12.8594 3.74219 12 3.42969V1.51562C12 1.32031 11.8438 1.125 11.6094 1.08594C10.1641 0.734375 8.71875 0.773438 7.35156 1.08594C7.11719 1.125 7 1.32031 7 1.51562V3.42969C6.10156 3.74219 5.32031 4.21094 4.61719 4.83594L2.9375 3.85938C2.74219 3.74219 2.50781 3.78125 2.35156 3.9375C1.41406 4.99219 0.671875 6.24219 0.242188 7.64844C0.164062 7.84375 0.242188 8.07812 0.4375 8.19531L2.11719 9.13281C1.96094 10.0703 1.96094 10.9688 2.11719 11.9062L0.4375 12.8438C0.242188 12.9609 0.164062 13.1953 0.242188 13.3906C0.671875 14.7969 1.41406 16.0469 2.35156 17.1016C2.50781 17.2578 2.74219 17.2969 2.9375 17.1797L4.61719 16.2422C5.32031 16.8281 6.10156 17.2969 7 17.6094V19.5234C7 19.7188 7.15625 19.9141 7.35156 19.9922C8.79688 20.3047 10.2422 20.2656 11.6094 19.9922C11.8438 19.9141 12 19.7188 12 19.5234V17.6094C12.8594 17.2969 13.6406 16.8281 14.3438 16.2422L16.0234 17.1797C16.2188 17.2969 16.4531 17.2578 16.6094 17.1016C17.5859 16.0469 18.2891 14.7969 18.7578 13.3906C18.7969 13.1953 18.7188 12.9609 18.5234 12.8438ZM9.5 13.625C7.74219 13.625 6.375 12.2578 6.375 10.5C6.375 8.78125 7.74219 7.375 9.5 7.375C11.2188 7.375 12.625 8.78125 12.625 10.5C12.625 12.2578 11.2188 13.625 9.5 13.625Z"
        fill="#A1A7B8"
      />
    </svg>
  ),

  SELECT: <CheckCircleOutlined />,

  // t
  TV: (
    <svg
      width="90"
      height="60"
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.5 30H6.5C6.1875 30 6 30.25 6 30.5V31.5C6 31.8125 6.1875 32 6.5 32H33.5C33.75 32 34 31.8125 34 31.5V30.5C34 30.25 33.75 30 33.5 30ZM38 0H2C0.875 0 0 0.9375 0 2V24C0 25.125 0.875 26 2 26H38C39.0625 26 40 25.125 40 24V2C40 0.9375 39.0625 0 38 0ZM38 24H2V2H38V24Z"
        fill="#A1A7B8"
      />
    </svg>
  ),
  TV_MENU: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M21 3H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2L23 5c0-1.1-.9-2-2-2zm0 14H3V5h18v12z" />
    </svg>
  ),
  TV_APP: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25px"
      height="25px"
      viewBox="0 0 50 50"
      fill="#e2ebf0"
    >
      <path d="M16.5 42V38H7Q5.8 38 4.9 37.1Q4 36.2 4 35V9Q4 7.8 4.9 6.9Q5.8 6 7 6H41Q42.2 6 43.1 6.9Q44 7.8 44 9V35Q44 36.2 43.1 37.1Q42.2 38 41 38H31.5V42ZM7 35H41Q41 35 41 35Q41 35 41 35V9Q41 9 41 9Q41 9 41 9H7Q7 9 7 9Q7 9 7 9V35Q7 35 7 35Q7 35 7 35ZM7 35Q7 35 7 35Q7 35 7 35V9Q7 9 7 9Q7 9 7 9Q7 9 7 9Q7 9 7 9V35Q7 35 7 35Q7 35 7 35Z" />
    </svg>
  ),
  TRANSLATE: (
    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
      <path d="M23.75 44 32.8 20H36.9L46.2 44H41.85L39.8 37.7H30.2L27.85 44ZM31.3 34.2H38.4L34.9 24.5H34.8ZM8 38 5.25 35.25 15.45 25.05Q13.55 22.85 12.075 20.625Q10.6 18.4 9.5 16H13.85Q14.7 17.65 15.725 19.125Q16.75 20.6 18.05 22.15Q20.3 19.8 21.8 17.275Q23.3 14.75 24.35 12H2V8H16V4H20V8H34V12H28.35Q27.25 15.45 25.425 18.775Q23.6 22.1 20.95 25.1L25.85 30.05L24.35 34.1L18 28Z" />
    </svg>
  ),
  // u
  USER: <i className="fas fa-user" />,
  USERS: (
    <svg
      width="90"
      height="60"
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34 12C36.75 12 39 9.8125 39 7C39 4.25 36.75 2 34 2C31.1875 2 29 4.25 29 7C29 9.8125 31.1875 12 34 12ZM34 4C35.625 4 37 5.375 37 7C37 8.6875 35.625 10 34 10C32.3125 10 31 8.6875 31 7C31 5.375 32.3125 4 34 4ZM20 14C23.8125 14 27 10.875 27 7C27 3.1875 23.8125 0 20 0C16.125 0 13 3.1875 13 7C13 10.875 16.125 14 20 14ZM20 2C22.75 2 25 4.25 25 7C25 9.8125 22.75 12 20 12C17.1875 12 15 9.8125 15 7C15 4.25 17.1875 2 20 2ZM35.25 14H32.75C31.75 14 30.875 14.3125 30.125 14.8125C30.75 15.25 31.25 15.6875 31.75 16.25C32.0625 16.125 32.375 16 32.75 16H35.25C36.75 16 38 17.375 38 19C38 19.5625 38.4375 20 39 20C39.5 20 40 19.5625 40 19C40 16.25 37.8125 14 35.25 14ZM6 12C8.75 12 11 9.8125 11 7C11 4.25 8.75 2 6 2C3.1875 2 1 4.25 1 7C1 9.8125 3.1875 12 6 12ZM6 4C7.625 4 9 5.375 9 7C9 8.6875 7.625 10 6 10C4.3125 10 3 8.6875 3 7C3 5.375 4.3125 4 6 4ZM25 15.25C22.875 15.25 22.375 16 20 16C17.5625 16 17.0625 15.25 14.9375 15.25C12.6875 15.25 10.5 16.3125 9.1875 18.1875C8.4375 19.375 8 20.75 8 22.25V25C8 26.6875 9.3125 28 11 28H29C30.625 28 32 26.6875 32 25V22.25C32 20.75 31.5 19.375 30.75 18.1875C29.4375 16.3125 27.25 15.25 25 15.25ZM30 25C30 25.5625 29.5 26 29 26H11C10.4375 26 10 25.5625 10 25V22.25C10 21.1875 10.25 20.1875 10.875 19.3125C11.6875 18.0625 13.25 17.25 14.9375 17.25C16.6875 17.25 17.3125 18 20 18C22.6875 18 23.25 17.25 25 17.25C26.6875 17.25 28.25 18.0625 29.0625 19.3125C29.6875 20.1875 29.9375 21.1875 29.9375 22.25V25H30ZM9.8125 14.8125C9.0625 14.3125 8.1875 14 7.25 14H4.75C2.125 14 0 16.25 0 19C0 19.5625 0.4375 20 1 20C1.5 20 2 19.5625 2 19C2 17.375 3.1875 16 4.75 16H7.25C7.5625 16 7.875 16.125 8.1875 16.25C8.6875 15.6875 9.1875 15.25 9.8125 14.8125Z"
        fill="#A1A7B8"
      />
    </svg>
  ),
  USER_PLUS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="white"
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <path d="M20,9V6h-2v3h-3v2h3v3h2v-3h3V9H20z M9,12c2.21,0,4-1.79,4-4c0-2.21-1.79-4-4-4S5,5.79,5,8C5,10.21,6.79,12,9,12z M9,6 c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2S7,9.1,7,8C7,6.9,7.9,6,9,6z M15.39,14.56C13.71,13.7,11.53,13,9,13c-2.53,0-4.71,0.7-6.39,1.56 C1.61,15.07,1,16.1,1,17.22V20h16v-2.78C17,16.1,16.39,15.07,15.39,14.56z M15,18H3v-0.78c0-0.38,0.2-0.72,0.52-0.88 C4.71,15.73,6.63,15,9,15c2.37,0,4.29,0.73,5.48,1.34C14.8,16.5,15,16.84,15,17.22V18z" />
      </g>
    </svg>
  ),
  USER_MINUS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="white"
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <path d="M14,8c0-2.21-1.79-4-4-4C7.79,4,6,5.79,6,8c0,2.21,1.79,4,4,4C12.21,12,14,10.21,14,8z M12,8c0,1.1-0.9,2-2,2 c-1.1,0-2-0.9-2-2s0.9-2,2-2C11.1,6,12,6.9,12,8z" />
          <path d="M2,18v2h16v-2c0-2.66-5.33-4-8-4C7.33,14,2,15.34,2,18z M4,18c0.2-0.71,3.3-2,6-2c2.69,0,5.77,1.28,6,2H4z" />
          <rect height="2" width="6" x="17" y="10" />
        </g>
      </g>
    </svg>
  ),
  USER_ACCOUNTS: (
    <svg
      width="90"
      height="60"
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 30C2.4375 30 2 29.5625 2 29V26.4375C2 22.875 4.8125 20.0625 8.375 20C9.625 20 10.8125 21 14 21C17.125 21 18.375 20 19.5625 20C19.75 20 19.875 20.0625 20.0625 20.0625C20.1875 19.5 20.5625 18.625 20.875 18.1875C20.5 18.125 19.9375 18.0625 19.5625 18C17.75 18 16.9375 19 13.9375 19C11 19 10.1875 18 8.375 18C3.75 18 0 21.8125 0 26.4375V29C0 30.6875 1.3125 32 3 32H21C20.625 31.5625 20.1875 30.625 20.0625 30H3ZM14 16C18.375 16 22 12.4375 22 8C22 3.625 18.375 0 14 0C9.5625 0 6 3.625 6 8C6 12.4375 9.5625 16 14 16ZM14 2C17.25 2 20 4.75 20 8C20 11.3125 17.25 14 14 14C10.6875 14 8 11.3125 8 8C8 4.75 10.6875 2.0625 14 2ZM31 23C29.875 23 29 23.9375 29 25C29 26.125 29.875 27 31 27C32.0625 27 33 26.125 33 25C33 23.9375 32.0625 23 31 23ZM37 18H36V15C36 12.25 33.75 10 31 10C28.1875 10 26 12.25 26 15V18H25C23.3125 18 22 19.375 22 21V29C22 30.6875 23.3125 32 25 32H37C38.625 32 40 30.6875 40 29V21C40 19.375 38.625 18 37 18ZM28 15C28 13.375 29.3125 12 31 12C32.625 12 34 13.375 34 15V18H28V15ZM38 29C38 29.5625 37.5 30 37 30H25C24.4375 30 24 29.5625 24 29V21C24 20.5 24.4375 20 25 20H37C37.5 20 38 20.5 38 21V29Z"
        fill="#A1A7B8"
      />
    </svg>
  ),
  // v
  // w
  WI_FI: (
    <svg
      width="12"
      height="17"
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.875 8.5C2.875 8.89844 2.52344 9.25 2.125 9.25C1.70312 9.25 1.375 8.89844 1.375 8.5C1.375 8.07812 1.70312 7.75 2.125 7.75C2.52344 7.75 2.875 8.07812 2.875 8.5ZM4 8.5C4 7.44531 3.15625 6.625 2.125 6.625C1.07031 6.625 0.25 7.44531 0.25 8.5C0.25 9.53125 1.07031 10.375 2.125 10.375C3.15625 10.375 4 9.53125 4 8.5ZM4.72656 13.2812C4.84375 13.3984 5.03125 13.3984 5.125 13.2812C7.60937 10.5625 7.60937 6.41406 5.125 3.69531C5.03125 3.57812 4.84375 3.57812 4.72656 3.69531L4.32812 4.07031C4.21094 4.1875 4.21094 4.35156 4.30469 4.46875C6.36719 6.74219 6.39062 10.2344 4.30469 12.5078C4.21094 12.625 4.21094 12.7891 4.32812 12.9062L4.72656 13.2812ZM7.86719 15.9062C11.6875 11.7109 11.6875 5.24219 7.86719 1.07031C7.77344 0.953124 7.58594 0.953124 7.46875 1.07031L7.07031 1.44531C6.97656 1.5625 6.95312 1.72656 7.07031 1.84375C10.4687 5.59375 10.4922 11.3828 7.07031 15.1328C6.95312 15.25 6.97656 15.4141 7.07031 15.5312L7.46875 15.9062C7.58594 16.0234 7.77344 16.0234 7.86719 15.9062Z"
        fill="white"
      />
    </svg>
  ),
  // x
  X: (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.78516 5.75L11.4062 2.16406L12.1445 1.42578C12.25 1.32031 12.25 1.14453 12.1445 1.00391L11.3711 0.230469C11.2305 0.125 11.0547 0.125 10.9492 0.230469L6.625 4.58984L2.26562 0.230469C2.16016 0.125 1.98438 0.125 1.84375 0.230469L1.07031 1.00391C0.964844 1.14453 0.964844 1.32031 1.07031 1.42578L5.42969 5.75L1.07031 10.1094C0.964844 10.2148 0.964844 10.3906 1.07031 10.5312L1.84375 11.3047C1.98438 11.4102 2.16016 11.4102 2.26562 11.3047L6.625 6.94531L10.2109 10.5664L10.9492 11.3047C11.0547 11.4102 11.2305 11.4102 11.3711 11.3047L12.1445 10.5312C12.25 10.3906 12.25 10.2148 12.1445 10.1094L7.78516 5.75Z"
        fill="#6A7082"
      />
    </svg>
  ),
  // y
  // z
};

export default ICONS;
