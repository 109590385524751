import { useEffect, useState } from "react";
import { Button, notification, Table, Typography, Tag } from "antd";
import Page from "../../../elements/Page";

import HeaderItem from "../../../elements/HeaderItem";
import TableHead from "./TableHead";
import REQUESTS from "../../../../server/requests";
import ICONS from "../../../../config/icons";
import TableButtons from "../../../elements/TableButtons";
import ImageComponent from "../../../elements/ImageComponent";
import confirm from "antd/lib/modal/confirm";
// import StoryDrawer from "./drawer/StoryDrawer";
import { useSelector } from "react-redux";
import EN from "../../../../config/en";
import AddButtonComponent from "../../../elements/AddButtonComponent";

const CustomerPage = () => {
  const translation = useSelector((state) => state.globalState.translate);

  let debounceTimeOut;

  const [dataSource, setDataSource] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [editable, setEditable] = useState(null);
  const [loading, setLoading] = useState(false);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 50,
      fixed: "left",
    },
    {
      title: translation["Image"] || EN["Image"],
      dataIndex: "image",
      key: "image",
      align: "center",
      render: (text, record) => {
        return <ImageComponent src={text} className="tableImage" />;
      },
    },
    {
      title: translation["Name"] || EN["Name"],
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: translation["Country"] || EN["Country"],
      dataIndex: "country",
      key: "country",
      align: "center",
    },
    {
      title: translation["Rating"] || EN["Rating"],
      dataIndex: "rating",
      key: "rating",
      align: "center",
    },
    {
      title: translation["Feedback"] || EN["Feedback"],
      dataIndex: "feedbacks",
      key: "feedbacks",
      align: "center",

      ellipsis: true,
      // render: (text, record) => {
      //   return (
      //     <Tag color="green" key={record.id}>
      //       {record.feedbacks}
      //     </Tag>
      //   );
      // },
    },
  ];

  const getDataSource = () => {
    setLoading(true);

    const query = {
      limit,
      page: currentPage,
      total,
    };

    const successCallBack = (data) => {
      setLoading(false);
      setDataSource(data?.rows);
      console.log(data);
    };

    const errorCallBack = (err) => {
      setLoading(false);
      console.log(err);
    };

    try {
      REQUESTS.BOOKING.CUSTOMER_STORY.GET(query, successCallBack, errorCallBack);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
    setTotal(pagination.total);
  };

  useEffect(() => {
    getDataSource();
  }, [currentPage, limit, total]);

  return (
    <Page>
      <div className="page-body">
        <h1>{translation["Customer Story"] || EN["Customer Story"]}</h1>
        <HeaderItem title={"Total"} total={dataSource?.length} />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        ></div>
        <Table
          columns={columns}
          dataSource={dataSource}
          onChange={handleTableChange}
          loading={loading}
          pagination={{
            position: ["bottomCenter"],
            current: currentPage,
            pageSize: limit,
            total: total,
            limit,
            showSizeChanger: true,
          }}
        />
      </div>
      {/* <StoryDrawer
        visible={showDrawer}
        onClose={() => {
          setShowDrawer(false);
          setEditable(null);
        }}
        editable={editable}
        country={countryList}
        addMusic={handleAddAboutInfo}
        loading={loading}
      /> */}
    </Page>
  );
};

export default CustomerPage;
