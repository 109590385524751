import { useEffect, useState } from "react";
import { Button, notification, Table, Typography, Tag } from "antd";
import Page from "../../../elements/Page";

import HeaderItem from "../../../elements/HeaderItem";
import TableHead from "./TableHead";
import REQUESTS from "../../../../server/requests";
import ICONS from "../../../../config/icons";
import TableButtons from "../../../elements/TableButtons";
import ImageComponent from "../../../elements/ImageComponent";
import confirm from "antd/lib/modal/confirm";
import { useSelector } from "react-redux";
import EN from "../../../../config/en";
import AddButtonComponent from "../../../elements/AddButtonComponent";
import ServicePageDrawer from "./drawer/ServicePageDrawer";

const ServicePage = () => {
  const translation = useSelector((state) => state.globalState.translate);

  let debounceTimeOut;

  const [dataSource, setDataSource] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [editable, setEditable] = useState(null);
  const [loading, setLoading] = useState(false);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 50,
      fixed: "left",
    },
    {
      title: translation["Image"] || EN["Image"],
      dataIndex: "image",
      key: "image",
      align: "center",
      render: (text, record) => {
        return <ImageComponent src={text} className="tableImage" />;
      },
    },
    {
      title: translation["Title"] || EN["Title"],
      dataIndex: "title",
      key: "title",
      align: "center",
    },

    {
      title: translation["Text"] || EN["Text"],
      dataIndex: "text",
      key: "text",
      align: "center",
      ellipsis: true,
    },

    {
      title: translation["Actions"] || EN["Actions"],
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (text, record) => {
        return (
          <TableButtons
            handleMenuClick={(e) => handleMenuClick(e.key, record)}
            buttons={[
              { key: "edit", text: translation["Edit"] || EN["Edit"], icon: ICONS.EDIT },
              {
                key: "delete",
                text: translation["Delete"] || EN["Delete"],
                icon: ICONS.DELETE,
              },
            ]}
          />
        );
      },
    },
  ];

  const getDataSource = () => {
    setLoading(true);

    const query = {
      limit,
      page: currentPage,
      total,
    };
    try {
      const successCallBack = (data) => {
        setLoading(false);
        setDataSource(data.rows);
      };

      const errorCallBack = (err) => {
        setLoading(false);
        console.log(err);
      };

      REQUESTS.BOOKING.SERVICE.GET(query, successCallBack, errorCallBack);
    } catch (error) {
      setLoading(false);
      // throw error;
    }
  };

  const handleMenuClick = (key, record) => {
    switch (key) {
      case "edit":
        setEditable(record);
        setShowDrawer(true);

        break;
      case "delete":
        confirm({
          title: translation["Do you want to delete"] || EN["Do you want to delete"],
          onOk() {
            handleDelete(record.id);
          },
        });
        break;
      default:
        break;
    }
  };

  const handleAddServiceInfo = (values) => {
    setLoading(true);

    clearTimeout(debounceTimeOut);

    debounceTimeOut = setTimeout(() => {
      if (editable) {
        try {
          REQUESTS.BOOKING.SERVICE.EDIT(
            values,
            editable.id,

            (data) => {
              setLoading(false);
              notification.success({
                message:
                  translation["Service info updated successfully"] ||
                  EN["Service info updated successfully"],
              });
              setEditable(null);
              setShowDrawer(false);
              getDataSource();
            },
            (error) => {
              setLoading(false);

              console.log(error);
            }
          );
        } catch (error) {
          setLoading(false);

          console.log(error);
        }
      } else {
        try {
          REQUESTS.BOOKING.SERVICE.ADD(
            values,
            (data) => {
              setLoading(false);

              notification.success({
                message:
                  translation["Service added successfully"] ||
                  EN["Service added successfully"],
              });
              setShowDrawer(false);
              getDataSource();
            },
            (error) => {
              setLoading(false);

              console.log(error);
            }
          );
        } catch (error) {
          setLoading(false);

          console.log(error);
        }
      }
    }, 1000);
  };

  const handleDelete = (id) => {
    setLoading(true);
    try {
      REQUESTS.BOOKING.SERVICE.DELETE(
        id,
        (data) => {
          setLoading(false);
          getDataSource();
          notification.success({
            message:
              translation["Service info deleted successfully"] ||
              EN["Service info deleted successfully"],
          });
        },
        (error) => {
          setLoading(false);
          console.log(error);
        }
      );
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
    setTotal(pagination.total);
  };

  useEffect(() => {
    getDataSource();
  }, [currentPage, limit, total]);

  return (
    <Page>
      <div className="page-body">
        <h1>{translation["Service Page"] || EN["Service Page"]}</h1>
        <HeaderItem title={"Total"} total={dataSource?.length} />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <TableHead>
            <div
              style={{
                display: "flex",
                justifyContent: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <AddButtonComponent
                text={translation["Add Service"] || EN["Add Service"]}
                onClick={() => {
                  setShowDrawer(true);
                  setEditable(null);
                }}
              />
            </div>
          </TableHead>
        </div>
        <Table
          columns={columns}
          dataSource={dataSource}
          onChange={handleTableChange}
          loading={loading}
          pagination={{
            position: ["bottomCenter"],
            current: currentPage,
            pageSize: limit,
            total: total,
            limit,
            showSizeChanger: true,
          }}
        />
      </div>
      <ServicePageDrawer
        visible={showDrawer}
        onClose={() => {
          setShowDrawer(false);
          setEditable(null);
        }}
        editable={editable}
        AddService={handleAddServiceInfo}
        loading={loading}
      />
    </Page>
  );
};

export default ServicePage;
