import { useEffect, useState } from "react";
import { Drawer, Select, Button } from "antd";

const BookPageDrawer = ({ open, onClose, room, bookingId, save, loading }) => {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);

  const handleChange = (value) => {
    setValue(value);
  };

  useEffect(() => {
    const success = (response) => {
      const options = response?.map((item) => ({
        label: item?.location_name,
        value: item?.id,
      }));
      console.log(options, "options");

      setOptions(options);
    };

    const failure = (error) => {
      console.log(error);
    };

    room(success, failure);
  }, [open]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      destroyOnClose
      title="Accept book"
      footer={
        <div style={{ textAlign: "right" }}>
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button
            type="primary"
            loading={loading}
            onClick={() => {
              save(value);
            }}
          >
            Submit
          </Button>
        </div>
      }
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <h3>Room</h3>
        <Select
          onChange={handleChange}
          style={{ width: "100%" }}
          placeholder="Select room"
          options={options}
          showSearch
          allowClear
        />
      </div>
    </Drawer>
  );
};

export default BookPageDrawer;
