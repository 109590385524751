const dashboard = "/dashboard";

const NAVIGATORS = {
  // a
  APPLICATION: `${dashboard}/application`,
  // b
  BOOKING_ABOUT: `${dashboard}/booking-about`,
  BOOKING_CUSTOMERS: `${dashboard}/customer`,
  BOOKING_CUSTOMER_STORY: `${dashboard}/booking-customer-story`,
  BOOKING_SOCIAL_MEDIA: `${dashboard}/booking-social-media`,
  BOOKING_ARTICLES: `${dashboard}/booking-articles`,
  BOOKING_SERVICE: `${dashboard}/booking-service`,
  BOOKING_BLOG: `${dashboard}/booking-blog`,
  BOOKING_SOCIAL_AUTH: `${dashboard}/booking-social-auth`,
  BOOKING_SLIDER: `${dashboard}/booking-slider`,
  BOOKING_BILLING_HISTORY: `${dashboard}/booking-billing-history`,
  BOOKING_ROOM_ROOMS: `${dashboard}/booking-room-rooms`,
  BOOKING_ROOM_PROPERTIES: `${dashboard}/booking-room-properties`,
  BOOKING_ROOM_FACILITIES: `${dashboard}/booking-room-facilities`,
  BOOKING_BOOK: `${dashboard}/booking-room-booking`,

  // c
  CHECK_EMAIL: "/check-email",
  // d
  DASHBOARD: dashboard,
  DASHBOARD_CONTENT: `${dashboard}/content`,

  // e
  ECOMMERCE_PRODUCTS: `${dashboard}/ecommerce-products`,
  ECOMMERCE_CATEGORIES: `${dashboard}/ecommerce-categories`,
  ECOMMERCE_SERVICES: `${dashboard}/ecommerce-services`,

  // f
  // g
  // h
  HOST_GROUPS: `${dashboard}/host-groups`,
  HELP_INFO: `${dashboard}/help-info`,

  // i
  IT: `${dashboard}/it`,
  INFO_CENTER: `${dashboard}/info-center`,
  INFO_CENTER_ORGANIZATION_INFO: `${dashboard}/info-center-organization-info`,
  INFO_CENTER_LOCAL_INFO: `${dashboard}/info-center-local-info`,
  INFO_CENTER_USEFUL_INFO: `${dashboard}/info-center-useful-info`,
  INFO_CENTER_CATEGORIES: `${dashboard}/info-center-categories`,

  // j
  // k
  // l
  LOGIN: "/login",
  LIVE_TV_CATEGORIES: `${dashboard}/live-tv-categories`,
  LIVE_TV_GENERALS: `${dashboard}/live-tv-generals`,
  LIVE_TV_EPG_LINKS: `${dashboard}/live-tv-epg-links`,
  LIVE_TV_TVCHANNELS: `${dashboard}/live-tv-channels`,
  LOCATIONS: `${dashboard}/locations`,
  LOCATION: `${dashboard}/location`,

  // m
  MEDIA: `${dashboard}/media`,
  MEDIA_VOD: `${dashboard}/media-vod`,
  MEDIA_VOD_GENRES: `${dashboard}/media-vod-genres`,
  MEDIA_VOD_MOVES: `${dashboard}/media-vod-moves`,
  MEDIA_LIBRARY: `${dashboard}/media-library`,
  MESSAGES: `${dashboard}/messages`,
  MONITORING: `${dashboard}/monitoring`,
  MUSIC: `${dashboard}/music`,
  MUSIC_GENRES: `${dashboard}/music-genres`,
  // n
  NEWS: `${dashboard}/news`,
  // o
  ORDERS: `${dashboard}/orders`,
  // ORDERS_PRODUCTS: `${dashboard}/orders-products`,
  // ORDERS_SERVICES: `${dashboard}/orders-services`,
  ORGANIZATION_CATEGORIES: `${dashboard}/organization-categories`,
  ORGANIZATION_COMPANIES: `${dashboard}/organization-companies`,

  // p

  PACKAGES: `${dashboard}/packages`,
  PRODUCTS: `${dashboard}/products`,
  PROMOTIONS_NEWS: `${dashboard}/promotions-news`,
  PROMOTIONS_CATEGORIES: `${dashboard}/promotions-categories`,

  // q
  // r
  ROOMS: `${dashboard}/rooms`,
  RESET_PASSWORD: "/reset-password",
  ROOM: `${dashboard}/room`,
  RADIO: `${dashboard}/radio`,
  RADIO_GENRES: `${dashboard}/radio-genres`,

  // s
  SERVICES_PRODUCTS: `${dashboard}/services-products`,
  SERVICES_CATEGORIES: `${dashboard}/services-categories`,
  SETTINGS: `${dashboard}/settings`,
  SCREEN_MIRRORING: `${dashboard}/screen-mirroring`,
  // t
  TV_APPLICATION: `${dashboard}/tv-application`,
  TRANSLATION: `${dashboard}/translation`,
  // u
  // v
  // w
  // x
  // y
  // z
};

export default NAVIGATORS;
